@import '../../../../scss/mixins';

.contentBlock {
    margin: 36px 0;
}
.flexColumn {
    @include flexColumn;
}
.contentTitle {
    margin-bottom: 16px;
    margin-right: 36px;
    width: 626px;

    @media (max-width: $mobile-big) {
        width: -webkit-fill-available;
        margin-right: 0;
    }

    h4 {
        @include title;
        margin: 16px 0;
    }
}
.contentContent {
    display: flex;

    @media (max-width: $mobile-big) {
        flex-direction: column;
    }
}
.twoBlocks {
    display: flex;
    justify-content: space-between;

    @media (max-width: $mobile-big) {
        flex-direction: column;
    }
}
.contestImg {
    width: 500px;
    height: auto;
    max-height: 300px;
    object-fit: contain;
    border-radius: 4px;
    border: 1px solid $secondary-gray;
    margin: 16px 0;
	cursor: pointer;

    @media (max-width: $mobile-big) {
        width: -webkit-fill-available;
    }
}
.contestContentBlock {
    margin: 36px 0;
}
.contestStepsBlock {
    @include flexRow;

    @media (max-width: $tablet) {
        @include flexColumn;
    }
}
.stepsContainer {
    margin: 0px 36px 0 16px;
    min-width: 150px;
    width: 100px;

    @media (max-width: $tablet) {
        margin: 0px 16px 36px 0;
        width: -webkit-fill-available;
    }
}
.stepTitle {
    margin-bottom: 36px !important;

    @media (max-width: $tablet) {
        display: none;
    }
}
.stepItem {
    > button {
        margin: 12px 0;
    }
}
.stepTitle {
    margin: -2px 0 6px 0;
}
.imageContainer {
    display: flex;
    justify-content: center;
}
.proposalImg {
    @include proposalImg;
}
.votingImg {
    width: 186px;
    height: 100%;
}
.imageCardSkeleton {
    width: 348px;
    height: 200px;
    object-fit: cover;
    border-radius: 4px 4px 0 0;
    background: $primary-gray;
    filter: brightness(0.95);

    @media (max-width: $mobile) {
        width: -webkit-fill-available;
    }
}
.overviewMessageBox {
    @include overviewMessageBox;
}
.proposalsContainer {
    @include flexRow;
    flex-wrap: wrap;
    margin: 16px 0;
}
.proposalCard {
    position: relative;
    background: $white;
    border: 1px solid $secondary-gray;
    border-radius: 4px;
    margin: 0 16px 16px 0;

    &:hover {
        box-shadow: 0 6px 16px rgba(0,0,0,.2);
        transform: translateY(-2px);
		cursor: pointer;
    }
    @media (max-width: $mobile-big) {
        width: -webkit-fill-available;
        margin: 0 0 16px 0;
    }
}
.skeletonProposal {
    width: 350px;

    @media (max-width: $mobile) {
        width: -webkit-fill-available;
    }
}
.proposalHeader {
    @include flexRow;
    justify-content: space-between;
}
.proposalCardContent {
    padding: 16px;
}
.feedbackToggle {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}
.contestContent {
    margin: 16px 0;
    p {
        padding: 4px 16px;
    }

    .ratingContent {
        @include flexRow;
        justify-content: space-between;
    }
    .listModal {
        padding: 0 16px;
        margin: 16px;
        list-style-type: disc;

        li  {
            margin: 4px 0;
        }
    }
}
.fullCard {
    border: none;
    width: 100%;
}
.ratingHearts {
    @include flexRow;
    margin: 16px;

    span > label > span > svg {
        width: 3em;
        height: 3em;
    }
}
.btn {
    @include btnPrimary;
}
.altBtn {
    @include btnPrimary;
    margin: 8px 0 !important;
    background-color: $secondary-black !important;
    width: 200px !important;
}
.indicatorContainer {
    margin: 36px 16px;

    @media (max-width: $mobile-big) {
        margin: 16px 0;
    }
}
.expanded {
    margin: 64px 0;

    @media (max-width: $mobile) {
        display: none;
    }
}
.winningProposalSpace {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 1;
    background-color: $white;
    border-radius: 50%;
}
.winningContainer {
    width: 40px;
    height: 40px;
    padding: 4px;
}
.contestSideContainer {
    @include flexRow;
    justify-content: space-between;

    @media (max-width: $tablet) {
        @include flexColumn;
        margin: 16px 0;
    }
}
.tag {
    border-radius: 4px !important;
    margin: 8px 0 !important;
}
.winningIcon {
    margin: 8px;
    border-radius: 4px;
    color: $white;
    background-color: $gold;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
}
.projectStatus {
    border: 1px solid $secondary-gray;
    border-radius: 4px;
    padding: 8px 16px;
    width: 300px;
    height: max-content;
    margin: 16px 0;

    @media (max-width: $mobile-big) {
        display: contents;
    }
}
.phaseFeedbackRating {
    position: fixed;
    background: $white;
    bottom: 6rem;
    z-index: 10;
    width: 350px;
    right: 6rem;
    margin-bottom: 0;
}
.projectTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $tertiary-gray;
    padding-bottom: 8px;
}
.contestStatusBox {
    @include flexRow;
    justify-content: space-between;
    border-bottom: 1px solid $tertiary-gray;
    padding-bottom: 8px;
}
.projectStatusTitle {
    padding-bottom: 8px;
    border-bottom: 1px solid $tertiary-gray;
}
.projectStatusBox {
    padding: 16px 0;
}
.projectDetails {
    @include flexRow;
    justify-content: space-between;
    align-items: center;
    margin: 6px 0;

    a {
        text-decoration: none;
    }
    a:hover {
        text-decoration: underline;
    }
}
.marginBottom {
    margin-bottom: 16px;
}
.cardSkeleton {
    width: 425px !important;
    height: 450px !important;
    border-radius: 4px;
}
.stepPhaseBox {
    display: flex;
    align-items: center;
}
.cardNotConfirmed {
    display: flex;
    flex-direction: column;
}
.notification {
    @media (max-width: $mobile-big) {
        max-width: -webkit-fill-available !important;
    }
}
.tabs {
    width: 100%;
    overflow: hidden;

    ul {
        width: 100%;

        li, button {
            text-align: center !important;
            outline: none !important;
            text-transform: uppercase !important;
            letter-spacing: 2px !important;
        }
    }
}
.updatesContentContainer {
    height: 100%;
}
.lastUpdateBox {
    border-bottom: 1px solid $primary-gray;
    border-radius: 4px;
    @include flexRow;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    margin: 8px 0;

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px, rgba(0, 0, 0, 0.3) 0px 4px 28px;
    }
}
.projectStatusQuickAccess {
    @include projectStatusQuickAccess;
}
.summaryBlock {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 26px;

    @media (max-width: $mobile) {
        flex-direction: column;
        align-items: center;
        margin: 8px;
    }
}
.summaryBox {
    width: 150px;
    height: 100px;
    background-color: $primary-gray;
    border-radius: 4px;
    @include flexColumn;
    justify-content: space-evenly;
    align-items: center;
    margin-right: 8px;

    @media (max-width: $mobile) {
        height: 70px;
        margin: 8px;
    }
}
.elementsSummaryBox {
    display: flex;
    align-items: center;

    svg {
        margin-right: 6px;
    }
}
.elementBox {
    @include elementBox;

    @media (max-width: $mobile) {
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: -webkit-fill-available;
        margin: 8px 0;
    }
}
.shake {
    animation: shake-animation 4.72s ease infinite;
    transform-origin: 50% 50%;
}
@keyframes shake-animation {
    0% { transform:translate(0,0) }
   1.78571% { transform:translate(5px,0) }
   3.57143% { transform:translate(0,0) }
   5.35714% { transform:translate(5px,0) }
   7.14286% { transform:translate(0,0) }
   8.92857% { transform:translate(5px,0) }
   10.71429% { transform:translate(0,0) }
   100% { transform:translate(0,0) }
 }
.winningProposal {
    background: $primary-gray;
    border: 1px solid $primary-gray;
}
.naming {
    @include naming;
}
.fileName {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.characteristics {
    @include characteristics;
}
.actions {
    @include actions;
    width: 100px;

    @media (max-width: $mobile) {
        margin: 6px 0px;
    }
}
.experienceBlock {
    @include flexRow;
    margin: 16px 0;

    @media (max-width: $mobile-big) {
        width: -webkit-fill-available;
        flex-wrap: wrap;

        > div {
            margin: 8px;
        }
    }
}
.statisticContent {
    margin-top: 8px;
    min-width: 80px;
    width: 100%;
}
.divider {
    border-left: 1px solid $secondary-gray;
    margin: 0 16px;
}
.tile {
    border-radius: 4px;
    margin: 16px 0px;
    width: 480px !important;
}
.infoElements {
    @include infoElement;

    @media (max-width: $tablet) {
        width: 100%;
    }
}
.listItem {
    margin: 16px;
    list-style-type: disc;

    li  {
        margin: 6px 16px;
    }
}
.specialAttentionBlock {
    @include flexRow;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: $tablet) {
        @include flexColumn;
    }
}
.contributorsBubble {
    width: 36px;
    height: 36px;
    background: $secondary-gray;
    border-radius: 50%;
    @include flexRow;
    @include centeredItems;
    color: $white;
    font-weight: 600;
    margin: 4px;
}
.feedbackBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 6rem;
    right: 20px;
    position: fixed;
    background: $primary-gray;
    width: 58px;
    height: 58px;
    padding: 8px;
    cursor: pointer;
    border-radius: 50%;
    z-index: 10;

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px, rgba(0, 0, 0, 0.3) 0px 4px 28px;
    }
}
.boxDivider {
    padding-top: 16px;
    border-top: 1px solid $tertiary-gray;
}
.shadow {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px, rgba(0, 0, 0, 0.3) 0px 4px 28px;
}
.projectSubtitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
}
.bubbleIcons {
    z-index: 10;
    background: $secondary-gray;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    @include flexRow;
    @include centeredItems;
    margin-right: 8px;

    svg {
        opacity: 1;
    }
}
.checklistItem {
    display: flex;
    align-items: center;
    margin: 4px 0;
}
.clientFeedbackRating {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}
.clientFeedbackComment {
    width: 100%;
    background-color: $primary-gray;
    display: flex;
    height: 100%;
    align-items: center;
    padding: 1rem;
    border-radius: 4px;
    margin-top: 8px;
}
.imagesContainer {
    display: flex;
    flex-wrap: wrap;
}
.imgContainer {
    position: relative;
	z-index: 1;
    margin: 8px;
    width: 300px;
    height: 168px;
    border-radius: 4px;
    border: 1px solid $secondary-gray;
    background: $primary-gray;
    transition: all 0.1s ease-in-out;
    object-fit: contain;
}
.thumbnailImg {
	width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}
.contextContent {
    margin: 8px;
}
.colorBlock {
    margin: 8px;
    background-color: $primary-gray;
    border-radius: 4px;
    padding: 1rem;
    width: max-content;
    max-width: fit-content;
}
.listSpecialAttention {
    padding: 16px 36px;

    > ul {
        list-style: circle;
    }
}
.contestText {
    color: $secondary-black;
    margin: 0 8px;
}
.flexCenter {
    display: flex;
    align-items: center;
}
.infoNotification {
    @include flexRow;
    align-items: center;
    margin: 8px;

    svg {
        margin-right: 8px;
    }
}
.btnBox {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;

    @media (max-width: $tablet) {
        @include flexColumn;
    }
}
.iconText {
    margin-right: 6px !important;
    fill: $secondary-red !important;
}
.aBtn {
    padding: 8px !important;
}
.marginHeightShort {
    margin: 8px 0;
}
.marginHeightMedium {
    margin: 16px 4px;
}
.stepContainer {
    margin: 16px 0;
    padding: 16px;
    border: 1px solid $secondary-gray;
    border-radius: 12px;
}
.isActive {
    // box-shadow: 0 6px 16px rgba(0,0,0,.2);
    box-shadow: 8px 8px 0 rgba(32, 33, 37, 0.1);
    transition: box-shadow .5s,transform .5s,-webkit-box-shadow .5s,-webkit-transform .5s;
    transform: translateY(-4px);
}
.selectIcon {
    width: 48px;
    height: 48px;
}
.successColor {
    color: $green;
}
.pendingColor {
    color: $tertiary-gray;
}
.rateBlock {
    @include flexRow;
    justify-content: flex-end;

    > small {
        text-decoration: underline;
        color: $blue;
        cursor: pointer;
    }
}
.updateBlock {
    display: flex;
    margin: 16px 0;
}
.alignItems {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.line {
    border: 2px solid $yellow;
    border-radius: 1px;
    margin-right: 16px;
}
.stepIcon {
    width: 42px;
    height: 42px;
    background: $tertiary-gray;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    opacity: 0.8;
}
.phaseBubble {
    width: 48px;
    height: 48px;
    background: $secondary-gray;
    border-radius: 50%;
    @include flexRow;
    @include centeredItems;
    color: $white;
    font-weight: 600;
    margin: 4px;
    font-size: 18pt;
    cursor: pointer;
}
.headerSteps {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    flex-wrap: wrap;
}
.alignContainer {
    display: flex;
    align-items: center;
}
.quotationContainer {
    display: flex;
    flex-wrap: wrap;
    margin: 16px 0;
}
.alignStart {
    width: 100%;
    align-items: flex-start;
    flex-wrap: wrap;
}
div[id='expectations-list'] {
    @media (max-width: $mobile-big) {
        display: none;
    }
}
.filesList {
    display: flex;
    align-items: center;
    margin: 8px 0;
    flex-wrap: wrap;
}
.updateImg {
    width: 200px;
    height: 100px;
    object-fit: cover;
    border-radius: 4px;
    margin: 4px;
}
.statisticIcon {
    margin-right: 8px;
    fill: $tertiary-black;
}
.whiteLink {
    fill: $white;
}