@import '../../scss/mixins';

.imageContainer {
    display: flex;
    justify-content: center;
}
.proposalImg {
    @include proposalImg;
}
.proposalCard {
    position: relative;
    background: $white;
    border: 1px solid $secondary-gray;
    border-radius: 4px;
    margin: 0 16px 16px 0;

    &:hover {
        box-shadow: 0 6px 16px rgba(0,0,0,.2);
        transform: translateY(-2px);
		cursor: pointer;
    }
    @media (max-width: $mobile-big) {
        width: -webkit-fill-available;
        margin: 0 0 16px 0;
    }
}
.proposalHeader {
    @include flexRow;
    justify-content: space-between;
}
.proposalCardContent {
    padding: 16px;
}
.feedbackToggle {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}
.btn {
    @include btnPrimary;
}
.winningProposalSpace {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 1;
    background-color: $white;
    border-radius: 50%;
}
.tag {
    border-radius: 4px !important;
    margin: 8px 0 !important;
}
.notification {
    @media (max-width: $mobile-big) {
        max-width: -webkit-fill-available !important;
    }
}
.winningProposal {
    background: $primary-gray;
    border: 1px solid $primary-gray;
}
.projectSubtitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
}
.stepContainer {
    margin: 16px 0;
    padding: 16px;
    border: 1px solid $secondary-gray;
    border-radius: 12px;
}
.isActive {
    // box-shadow: 0 6px 16px rgba(0,0,0,.2);
    box-shadow: 8px 8px 0 rgba(32, 33, 37, 0.1);
    transition: box-shadow .5s,transform .5s,-webkit-box-shadow .5s,-webkit-transform .5s;
    transform: translateY(-4px);
}
.selectIcon {
    width: 48px;
    height: 48px;
}
.successColor {
    color: $green;
}
.pendingColor {
    color: $tertiary-gray;
}
.rateBlock {
    @include flexRow;
    justify-content: flex-end;

    > small {
        text-decoration: underline;
        color: $blue;
        cursor: pointer;
    }
}
.updateBlock {
    display: flex;
    margin: 16px 0;
}
.alignItems {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.line {
    border: 2px solid $yellow;
    border-radius: 1px;
    margin-right: 16px;
}
.stepIcon {
    width: 42px;
    height: 42px;
    background: $tertiary-gray;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    opacity: 0.8;
}
.phaseBubble {
    width: 48px;
    height: 48px;
    background: $secondary-gray;
    border-radius: 50%;
    @include flexRow;
    @include centeredItems;
    color: $white;
    font-weight: 600;
    margin: 4px;
    font-size: 18pt;
    cursor: pointer;
}
.headerSteps {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    flex-wrap: wrap;
}
.alignContainer {
    display: flex;
    align-items: center;
}
.quotationContainer {
    display: flex;
    flex-wrap: wrap;
    margin: 16px 0;
}
.filesList {
    display: flex;
    align-items: center;
    margin: 8px 0;
    flex-wrap: wrap;
}
.updateImg {
    width: 200px;
    height: 100px;
    object-fit: cover;
    border-radius: 4px;
    margin: 4px;
}
.whiteLink {
    fill: $white;
}