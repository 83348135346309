@import '../../../scss/mixins';

.startingContainer {
	width: 100%;
	@include responsiveContainer;
}

.title {
	text-align: center;
	@include title;
}

.title img {
	height: 70px;
	margin-bottom: 10px;
}

.header {
	display: flex;
	justify-content: center;
}

.headerContent {
	display: flex;
	align-items: flex-start;
}

.headerContent img {
	height: 100px;
	margin-right: 40px;
}

.description {
	font-size: 16px;
	margin-top: 10px;
	color: #6B6B6B;
	max-width: 320px;
	line-height: 1.2;
	text-align: center;
}

.contentWrapper {
	margin-top: 40px;
	display: flex;
	justify-content: center;
}

.content {
	width: 100%;
	max-width: 640px;
	display: flex;
	gap: 30px;
	opacity: 1;
	transition: 0.4s;
}

.contentHidden {
	opacity: 0;
}

.count {
	color: inherit;
	transition: 0.2s;
}

.countAnimation {
	color: #FFCF2D;
}

.left {
	height: 400px;
	width: 100%;
	border-radius: 16px;
	background-color: rgb(239, 239, 239);
	background-size: cover;
	background-position: center center;
	border: 1px solid rgb(221, 221, 221);
	background-image: url(./assets/placeholder.svg);
	margin-bottom: 20px;
}

.right {
	background-color: transparent;
	width: 90%;
}

.projectTitle {
	margin-top: 8px;
	font-size: 23px;
	margin-bottom: 20px;
	line-height: 1.2;
}

.sectionContent {
	font-size: 16px;
	line-height: 1.2;
	margin-top: 4px;
	color: #555555;
	margin-bottom: 30px;
}

.sectionName {
	text-transform: uppercase;
	color: #b1b1b1;
	margin-top: 15px;
	font-size: 13px;
	margin-bottom: 10px;
}

.openProjectBriefing {
	border: 1px solid rgb(213, 213, 213);
	color: gb(213, 213, 213);
	display: inline-block;
	padding: 10px;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s;
}

.openProjectBriefing:hover {
	background-color: rgba(213, 213, 213, 0.091);
}

.openProjectBriefingContent {
	display: flex;
	gap: 8px;
	align-items: center;
}

.actions {
	display: flex;
	gap: 20px;
}

.action {
	width: 60px;
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #fff;
	border-radius: 50%;
	font-size: 28px;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 10px 0px;
	transition: 0.3s;
	cursor: pointer;
	margin-top: 10px;
}

.action:hover {
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 20px 0px;
}

.modalContent {
	width: 100px;
	height: 100px;
}

.statisticsWrapper {
	display: flex;
	justify-content: center;
}

.statistics {
	max-width: 320px;
	background-color: #F5F5F5;
	font-size: 16px;
	padding: 20px;
	padding-top: 15px;
	padding-bottom: 15px;
	border-radius: 10px;
	color: #4e4e4e;
	line-height: 1.2;
	margin-top: 20px;
}

.empty {
	border: 1px solid #efefef;
	text-align: center;
	padding: 40px;
	width: 100%;
	border-radius: 10px;
	margin-left: -5px;
	width: calc(100% + 5px);
}
.empty img {
	height: 80px;
}

.emptyText {
	margin-top: 20px;
	color: #d8d8d8;
	font-size: 19px;
}

.descriptionWrapper {
	display: flex;
	justify-content: center;
}

.hide {
	display: none !important;
}

.totalsWrapper {
	display: flex;
	justify-content: center;
	margin-top: 40px;
	margin-bottom: 0px;
}

.totals {
	width: 90%;
	max-width: 320px;
	background-color: #f5f5f569;
	font-size: 16px;
	padding: 20px;
	padding-top: 25px;
	padding-bottom: 25px;
	border-radius: 10px;
	color: #4e4e4e8e;
	line-height: 1.2;
	text-align: center;
}

.popover {
	padding: 10px;
	padding-left: 20px;
	padding-right: 20px;
}

.popoverTitle {
	text-transform: uppercase;
	color: #b1b1b1;
	margin-top: 5px;
	font-size: 13px;
	margin-bottom: 10px;
}

.popover input {
	font-family: inherit;
	font-size: inherit;
	padding: 5px;
	padding-left: 10px;
	padding-right: 10px;
	border: 1px solid rgb(213, 213, 213);
	border-radius: 5px;
	font-size: 16px;
	outline: none;
	height: 40px;
}

.popover input:focus {
	border: 1px solid #017cf6;
}

.popoverForm {
	display: flex;
	align-items: center;
	gap: 10px;
}

.popoverButton {
	background-color: #017cf6;
	width: 50px;
	border-radius: 5px;
	color: #fff;
	transition: background-color 0.3s;
	cursor: pointer;
	justify-content: center;
	display: flex;
	align-items: center;
	font-size: 22px;
	height: 42px;
}

.popoverButton:hover {
	background-color: #0077ee;
}

@media screen and (max-width: 780px) {
	.header {
		display: block;
	}
	.headerContent img {
		margin-left: 35px;
	}
	.headerContent {
		display: block;
		text-align: center;
		width: 100%;
	}
	.headerTitle {
		width: 100%;
	}
	.descriptionWrapper {
		display: flex;
		justify-content: center;
		width: 100%;
	}
	.description {
		margin-left: auto;
		margin-right: auto;
	}
	.content {
		display: block;
	}
	.left {
		height: 140px;
	}
	.right {
		width: 100%;
		padding: 20px;
	}
}
