@import '../../../scss/mixins';

.startingContainer {
    @include responsiveContainer;
}
.title {
    @include title;
}
.tabs {
    margin-top: 16px;
}
.contentBlock {
    margin: 36px 0;
}
.contentTitle {
    @include contentTitle;
}
.listContent {
    @include listContent;
}