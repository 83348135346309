@import '../../../../scss/mixins';

.chip {
	background-color: #fff;
	display: inline-block;
	height: 44px;
	margin-left: 5px;
	margin-right: 10px;
	border-radius: 50px;
	min-width: 200px;
	border: 1px solid #EFEFEF;
	display: flex;
	padding-right: 25px;
	display: flex;
	align-items: center;
	cursor: pointer;
}

@media screen and (max-width: 780px) {
	.chip {
		margin-left: 0;
		margin-right: 0;
		margin-top: 10px;
		height: auto;
		border-radius: 6px;
		width: calc(100% + 35px);
		align-items: flex-start;
		padding: 10px;
		padding-right: 10px;
	}
}

.avatars {
	margin-left: 4px;
}

.result {
	font-size: inherit;
	margin-left: 10px;
	color: #6C6C6C;
}
