@import '../../../../scss/mixins';

.loadingContainer {
    margin: 36px 0;
}
.cardSkeleton {
    height: 400px !important;
    width: 900px !important;
    border-radius: 4px;
}
.contests {
    @include flexRow;
    flex-wrap: wrap;
    margin: 36px 0;
    @include responsiveContainer;
    @media (max-width: $tablet) {
        padding-left: 0;
        padding-right: 0;
    }
}
.contestCard {
    border: 1px solid $secondary-gray;
    border-radius: 4px;
    padding: 16px;
    width: -webkit-fill-available;
    max-width: 900px;
    margin-bottom: 16px;
	
    &:hover {
        box-shadow: 0 6px 16px rgba(0,0,0,.2);
        transform: translateY(-2px);
    }
}
.btnBottom {
    display: flex;
    justify-content: flex-end;
    width: -webkit-fill-available;
}
.noContent {
    @include flexRow;
    align-items: center;
    margin: 8px;

    svg {
        margin-right: 8px;
    }
}