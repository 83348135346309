$lastbasic-red: #df3d3b;
$lastbasic-dark-red: #c33735;

$interactive-01: $lastbasic-red;
$interactive-03: $lastbasic-red;
$interactive-04: $lastbasic-red;

$link-01: $lastbasic-red;
$focus: $lastbasic-red;
$hover-primary: $lastbasic-red;
$hover-primary-text: $lastbasic-red;
$hover-tertiary: $lastbasic-red;
$active-primary: $lastbasic-dark-red;
$active-tertiary: $lastbasic-dark-red;

$ui-05: $lastbasic-red;

@import "carbon-components/scss/globals/scss/styles.scss";

* {
	margin: 0px;
	padding: 0px;
}

body {
	font-weight: 400;

	h1, h2, h3 {
		font-weight: 800;
		color: #313131;
	}

	h4, h5, h6 {
		font-weight: 600;
		color: #313131;
	}

	p {
		font-size: 12pt;
		color: #3e3e3e;
	}

	small {
		font-size: 10pt;
		color: #3e3e3e;
	}
}