@import '../../../scss/mixins';

.profileContainer {
    width: 100%;
    @include responsiveContainer;
}
.title {
    @include title;
}
.subtitle {
    margin-top: 8px;
}
.secondaryTitle {
	font-size: 22px;
}
.titleSpace {
    margin-bottom: 8px;
}
.blockTitle {
    font-size: 16px;
    line-height: 20px;
    color: rgb(34, 34, 34);
    font-weight: 600;
    height: auto;
    margin-bottom: 8px;
}
.experienceBlock {
    @include flexRow;
    justify-content: center;
    margin: 36px 0;

    @media (max-width: $mobile-big) {
        margin: 36px 0;
        width: -webkit-fill-available;
    }
}
.divider {
    border-left: 1px solid $secondary-gray;
    margin: 0 16px;
}
.profileInfoContent {
    @include flexColumn;
    justify-content: center;
    margin: 36px 0;

    @media (max-width: $mobile-big) {
        @include flexColumn;
        align-items: center;
    }
}
.avatarContainer {
    @include flexColumn;
    @include centeredItems;
}
.avatarContainer img {
   border: 1px solid rgb(238, 238, 238);
}

.profileAvatar {
    @include avatar;
}
.uploadingAvatar {
    width: 126px;
    height: 126px;
    background: $secondary-gray;
    border-radius: 50%;
    @include flexRow;
    margin: 16px;
}
.avatarImg {
    @include avatarImg;
}
.infoElements {
    @include infoElement;
}
.section {
    margin: 36px 0;
}
.sectionContent {
    display: flex;
    align-items: center;

    @media (max-width: $mobile-big) {
        width: 100%;
    }
}
.toggle {
    margin: 0 16px;
}
.uploadAvatarBtn {
    position: absolute !important;
    bottom: 16px;
    right: 16px;
    border-radius: 2px !important;
    background-color: rgb(248, 248, 248) !important;
    color: rgb(143, 143, 143) !important;
}
.box {
    @include flexColumn;
    justify-content: space-between;
    min-width: 326px;
    max-width: 326px;
    width: 100%;
    min-height: 136px;
    box-shadow: rgba(0,0,0,0.12) 0px 6px 16px;
    padding: 16px;
    border-radius: 12px;
    text-decoration: none;

    &:hover {
        > svg, h5 {
            color: $secondary-red;
            fill: $secondary-red !important;
        }
    }
    @media (max-width: $mobile-big) {
        min-width: 100%;
    }
    @media (max-width: $mobile) {
        max-width: 82vw;
        min-width: 82vw;
    }
}

.icon {
    color: $primary-black;
    @include flexRow;
    align-items: center;
}
.svgIcon {
    margin-right: 16px;
}
.goArrow {
    fill: $tertiary-gray !important;
    background: $white;
    border-radius: 50%;
    padding: 4px;
    width: 26px;
    height: 26px;
}
.blocksListContainer {
    display: flex;
    flex-wrap: wrap;
    margin: 16px 0;
    gap: 16px;
}
.followIcon {
    &:hover {
        color: $secondary-red;
        cursor: pointer;
    }
}
.blockBox {
    margin: 0 8px 8px 0;
}
.chevronRight {
    margin: 8px 0;
    display: flex;
    justify-content: flex-end;
}
.bottomActions {
    display: flex;
    align-items: center;
}
