.feature {
	display: flex;
	align-items: flex-start;
	gap: 17px;
	padding: 17px;
	margin-top: 7px;
	background-color: transparent;
	position: relative;
}

.icon {
	width: 60px;
	height: 58px;
	margin-top: -5px;
	background-size: 60px auto;
	background-repeat: no-repeat;
	background-position: top center;
}

.title {
	font-size: 19px;
	font-weight: 600;
}

.description {
	font-size: 16px;
	margin-top: 3px;
}

.pilot {
	text-transform: uppercase;
	display: inline-block;
	font-size: 8px;
	font-weight: 400;
	color: #7A7C92;
	padding: 3px 5px 3px 5px;
	border-radius: 4px;
	border: .5px solid #7A7C92;
	vertical-align: 3px;
	margin-left: 8px;
	opacity: 0.4;
}

.featureBorder {
	border: 1px solid #dededede;
	border-radius: 10px;
	margin-top: 20px;
	padding-top: 24px;
	padding-bottom: 0px;
}

.featureBorder .description {
	padding-bottom: 24px;
}

.selectable {
	opacity: 1;
	transition: all 0.2s;
	cursor: pointer;
}

.selectable:hover {
	opacity: 0.85;
}

.selected {
	border: 3px solid #017cf6;
	margin-top: 18px;
	padding-top: 24px;
	padding-left: 15px;
	margin-bottom: -2px;
}

@keyframes blink { 50% { border-color: #017cf64a; }  }

.blinkingBorders {
	animation: blink 0.2s;
	animation-iteration-count: 3;
}

.smallFeature .text {
	display: flex;
	align-items: center;
	width: 100%;
}

.smallFeature .title {
	font-size: 16px;
	display: inline-block;
}

.smallFeature .description {
	display: inline-block;
	overflow-x: auto;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: 50%;
	padding: 0;
	margin-left: 10px;
	color: rgba(174, 174, 174, 0.691);
	margin-top: 2px;
	font-size: 13px;
	height: 14px;
}

.smallFeature .pilot {
	vertical-align: 2px;
	display: none;
}

.smallFeature .icon {
	width: 40px;
	min-width: 40px;
	height: 40px;
	margin-top: -8px;
	background-size: 100% !important;
	background-repeat: no-repeat;
	background-position: top center;
}

.featureBorder.smallFeature {
	border: 1px solid #dededede;
	border-radius: 10px;
	margin-top: 10px;
	padding-top: 23px;
	padding-bottom: 6px;
}

@media screen and (max-width: 545px) {
	.icon {
		width: 60px;
		min-width: 60px;
		height: 58px;
		margin-top: -5px;
		background-size: 100% !important;
		background-repeat: no-repeat;
		background-position: top center;
	}

	.smallFeature .description {
		display: none;
	}
}

.selectableDot {
	background-color: transparent;
	width: 20px;
	height: 20px;
	position: absolute;
	right: 10px;
	border-style: solid;
	border-width: 1px;
	border-color: #dededede;
	border-radius: 50%;
	top: 10px;
	transition: all 0.2s;
	display: flex;
	justify-content: center;
	align-items: center;
}

.selectableDot.selectedDot {
	background-color: #017cf6;
	border-color: #fefefede;
	right: 9px;
}
