@import '../../scss/_mixins.scss';

.container {
    height: 6px;
    position: fixed;
    top: 3rem;
    left: 0;
    z-index: 11;
    width: 100%;
}
.progressBar {
    height: 2px;
    border-radius: 0 50px 50px 0;
    background-color: rgb(243, 243, 243);
    display: none;
}
