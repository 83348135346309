@import '../../scss/_mixins.scss';

.modalContent {
    padding: 0 16px;

    .contestContent {
        margin: 16px 0;

        a {
            cursor: pointer;
        }
        p {
            padding: 4px 16px;
        }
        .listModal {
            padding: 0 16px;
            margin: 16px;
            list-style-type: disc;
        
            li  {
                margin: 4px 0;
            }
        }
    }
}
.ratingContent {
    @include flexRow;
    justify-content: space-between;
}
.ratingHearts {
    @include flexRow;
    justify-content: center;
    margin: 16px;

    span > label > span > svg {
        width: 2em;
        height: 2em;
    }
}

.notification {
    width: 100% !important;
}
.contestContent {
    margin: 16px 0;
    p {
        padding: 4px 16px;
    }
}
.imgContainer {
    display: flex;
    justify-content: center;
}
.proposalImg {
    max-height: 300px;
    border-radius: 4px;
    margin: 16px 0;
    cursor: pointer;
    border: 1px solid #DDD;
}