@import '../../../scss/mixins';

.proposalViewPage {
	position: absolute;
    top: 0;
	left: 0;
    z-index: 10000;
    background: $white;
    width: 100%;
    height: 100%;
	overflow-y: hidden;
}
.mainContainer {
	display: flex;
    gap: 16px;
    justify-content: space-between;
    height: 100vh;

	@media (max-width: $mobile-big) {
        flex-wrap: wrap;
    }
}
.closeIcon {
	cursor: pointer;
}
.typeLabel {
	&:hover {
		color: #df3d3b;
		font-weight: 600;
	}
}
.secondaryTitle {
	font-size: 22px;
}
.titleSpace {
    margin-bottom: 4px;
}
.imgContainer {
	position: relative;
	display: flex;
	justify-content: center;
	height: 100%;
	width: 100%;
	overflow-y: scroll;
}
.imgContent {
	position: absolute;
    left: 0;
	right: 0;
}
.proposalImg {

}
.contentSide {
	min-width: 400px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
	background: $white;

	@media (max-width: $mobile-big) {
        width: -webkit-fill-available;
		position: absolute;
    	bottom: 0;
    }
}
.sideContent {
	display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 26px;
    border-bottom: 1px solid rgb(221, 221, 221);
}
.winnerLabel {
	background-color: $gold;
    width: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.feedbackContent {
	margin: 8px 0;
}
.skeletonBlockContent {
	margin: 36px 0;
}
.listModal {
	padding: 0 16px;
	margin: 8px;
	list-style-type: circle;

	li  {
		margin: 4px 0;
	}
}
.btn {
    @include btnPrimary;
}
.button {
	margin: 0 8px !important;
}
.link {
    cursor: pointer;
	display: flex;
	align-items: center;
	padding: 4px;
	margin: 0 4px;

	&:hover {
		border-radius: 8px;
        background: #F7F7F7;
	}
}
.secondaryIcon {
	padding: 4px;
	border-radius: 8px;

	&:hover {
        background: #F7F7F7;
	}
}
.fixedActions {
	position: absolute;
    bottom: 0;
	padding: 16px;
    background: $white;
	width: -webkit-fill-available;
	display: flex;
    justify-content: flex-end;
	border-top: 1px solid #dddddd;
}
.flexColumn {
    @include flexColumn;
}
.externalLink {
	cursor: pointer;

	&:hover {
		text-decoration: underline;
		color: $blue;
	}
}
