@import '../../../scss/mixins';


.page {
	display: flex;
	justify-content: center;
}

.wrapper {
	width: 90%;
	max-width: 420px;
}

.content {

}

.title {
	text-align: center;
}

.yellowUnderline {
	text-decoration: underline;
	text-decoration-color: #FAE232;
}

.description {
	text-align: center;
	font-size: 16px;
	margin-top: 10px;
	line-height: 1.2;
}

.featureBox {
	border: 1px solid #dededede;
	border-radius: 10px;
	margin-top: 20px;
	padding-top: 10px;
	padding-bottom: 15px;
}

.selectBox {
	padding-top: 10px;
	margin-top: 0px;
	padding-bottom: 15px;
}

.buttonWrapper {
	margin-top: 20px;
	display: flex;
	justify-content: center;
	gap: 10px;
}

.button {
	display: inline-block;
	background-color: #017cf6;
	border-radius: 4px;
	color: #fff;
	transition: background-color 0.3s;
	cursor: pointer;
	font-size: 16px;
	padding: 13px 30px 13px 30px;
}

.button:hover {
	background-color: #0077ee;
}

.buttonGray {
	background-color: #c0c0c0;
	color: #fff;
	cursor: no-drop;
}

.buttonGray:hover {
	background-color: #c0c0c0;
}

.separatorWrapper {
	display: flex;
	justify-content: center;
}

.separator {
	width: 60%;
	height: 1px;
	background-color: #dedede6c;
}

.separatorWrapperBig {
	width: 120%;
	margin-left: -10%;
	margin-top: 40px;
	margin-bottom: 40px;
}

.separatorWrapperBig .separator {
	width: 100%;
}

.formBox {
	margin-top: 40px;
}

.question {
	margin-bottom: 30px;
}

.inputTitle {
	font-size: 15px;
	margin-left: 3px;
	font-weight: 600;
}

input, textarea, select {
	width: 100%;
	border: 1px solid #dededede;
	padding: 10px 12px 10px 12px;
	font-size: 17px;
	margin-top: 6px;
	border-radius: 6px !important;
}

textarea {
	height: 90px;
	line-height: 1.3;
	resize: none;
}

input::placeholder, textarea::placeholder {
	color: rgb(194, 195, 199);
}

.exposure {
	margin-top: 20px;
	background-color: rgba(228, 250, 255, 0.465);
	padding: 17px;
	border-radius: 0px;
	line-height: 1.2;
	color: rgb(31, 31, 31);
}

.editSelectionWrapper {
	display: flex;
	justify-content: center;
	padding-top: 0px;
	padding-bottom: 10px;
}

.editSelection {
	border-bottom: 1px solid #c9c9c9;
	color:#878787;
	font-size: 13px;
	transition: color 0.3s;
	cursor: pointer;
}

.editSelection:hover {
	color: #9b9b9b;
}

.table table {
	font-size: 16px;
	background-color: transparent;
	margin-bottom: -10px;
}

.table table td {
	padding-right: 50px;
	padding-bottom: 10px;
}

.table table tr {
	opacity: 0.5;
}

.buttonWrapperLong {
	justify-content: space-between;
	display: flex;
	gap: 10px;
	align-items: center;
	width: 110%;
	margin-left: -5%;
}

.buttonWrapperLong .button {
	width: 100%;
	text-align: center;
	padding-left: 7px;
	padding-right: 7px;
	align-items: center;
}

.buttonWrapperLong .button.buttonGrayFree, .buttonGrayBack {
	background-color: #F6F8F8;
	color: #5E5E5E;
	border: 1px solid #f2f2f2;
	transition: opacity 0.2s;
}

.buttonWrapperLong .button.buttonGrayFree:hover, .buttonGrayBack:hover {
	background-color: #F6F8F8;
	color: #5E5E5E;
	border: 1px solid #f2f2f2;
	opacity: 0.9;
}

.paymentInfo {
	margin-bottom: 20px;
	font-size: 16px;
	line-height: 1.2;
	color: #414141;
	width: 108%;
	margin-left: -4%;
	margin-bottom: 40px;
}

.buffer {
	height: 100px;
}

.extendedInformation {
	text-decoration: underline;
	text-decoration-style: dashed;
	text-decoration-color: rgb(205, 205, 205);

	cursor: pointer;
}

.clickableBold {
	font-weight: 600;
	cursor: pointer;
	transition: opacity 0.3s;
}

.clickableBold:hover {
	opacity: 0.9;
}

.error {
	text-align: center;
	background-color: rgb(255, 232, 232);
	color: rgb(162, 74, 74);
	border-radius: 4px;
	padding: 15px;
	font-size: 15px;
	margin-bottom: 5px;
}

.successMessage {
	text-align: center;
	margin-bottom: 10px;
	background-color: rgb(221, 254, 214);
	color: rgb(42, 94, 30);
	border-radius: 3px;
	padding: 12px;
	font-size: 12px;
	opacity: 1;
	margin-top: -20px;
}

.text {
	margin-top: 30px;
	font-size: 15px;
	margin-bottom: 0px;
	line-height: 1.1;
	width: 100%;
	text-align: left;
}

.textBlockTitle {
	font-size: 20px;
	color: inherit;
}

.textBlockDescription {
	font-size: 15px;
	color: #7E7E7E;
	margin-top: 7px;
}

.bold {
	font-weight: 600;
}

.textBlock {
	margin-bottom: 40px;
	display: flex;
	gap: 20px;
	align-items: flex-start;
}

.textWrapper {
	display: flex;
	justify-content: center;
}

.otherButton {
	text-decoration: underline;
	margin-top: -5px;
	opacity: 0.6;
	cursor: pointer;
}

.textImage {
	height: 50px;
	max-width: 50px;
	min-width: 50px;
	background-size: 50px;
	background-repeat: no-repeat;
	background-position: center;
	background-color: transparent;
	opacity: 0.9;
	@media (max-width: $mobile-big) {
		display: none;
	}
}