@import '../../scss/_mixins.scss';

.loading {
	position: absolute;
	left: calc(50% - 44px);
	top: calc(50% - 44px);
}
.previewFileModal {
	[class=bx--modal-content] {
		min-height: 70vh;
		padding: 16px !important;
	    margin: 0px !important;
		position: relative;
	}
	iframe {
		position: absolute;
		margin: -1rem;
		width: 100%;
		height: 100%;
		border: none;
	}
}
.moreContent {
	[class=bx--modal-content] {
		min-height: 80vh;
	}
	iframe {
		position: absolute;
		width: 100%;
		height: 70%;
		bottom: 0;
		left: 0;
		right: 0;
		border: none;
	}
}
.returnActionModal {
    @include flexRow;
    align-items: center;
	cursor: pointer;
	z-index: 10;
	padding: 12px;
	border-radius: 12px;
	background-color: $white;
	position: absolute;

	&:hover {
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .08);
	}

    span {
        color: $secondary-red;
    }
}
.previewInvoiceModal {
	[class=bx--modal-content] {
		min-height: 70vh;
	    margin: 0px !important;
	}
	iframe {
		position: absolute;
		margin: -1rem;
		width: 100%;
		height: calc(100% - 64px);
		border: none;
	}
}
.btnBox {
    display: flex;
    justify-content: flex-end;
}
.invoiceBtnBox {
	position: absolute;
    bottom: 0;
    right: 0;
	display: flex;

	@media (max-width: $mobile) {
		@include flexColumn;
	}
}
.btn {
    @include btnPrimary;
    width: 200px !important;
    margin: 8px !important;
}
.inlineLoading {
    justify-content: center !important;
    font-size: 1rem !important;
    color: $white !important;
}