@import '../../../../scss/mixins';

.title {
    @include title;
}
.subtitle {
    margin-top: 8px;
    font-weight: 400;
}
.contentBlock {
    margin: 36px 0;
}
.contentTitle {
    @include contentTitle;
}
.stepsContainer {
    margin: 36px 0 !important;
}
.elementBox {
    @include elementBox;
}
.naming {
    @include naming;
}
.actions {
    @include actions;
    width: 100px;
}
.notification {
    width: 100% !important;
}
.btn {
    @include btnPrimary;

    @media (max-width: $tablet) {
        margin: 4px 0 !important;
    }
}
.btnContainer {
    @include flexRow;
    justify-content: space-between;
    flex-wrap: wrap;

    position: fixed;
    bottom: -64px;
    left: 50%;
    right: 50%;
    background-color: $white;
    width: 100%;
    border-top: 1px solid $primary-gray;
    padding: 36px 0;
    @include responsiveContainer;
    transform: translate(-50%,-50%);
    z-index: 2;

    @media (max-width: $mobile-big) {
        padding: 2rem;
    }
}
.twoBtns > .btnContainer {
    @media (max-width: $mobile-big) {
        bottom: -72px;
        padding: .9rem 2rem;
    }
}
.contentSpace {
    padding-bottom: 116px;
}
.unique {
    justify-content: flex-end;
}
.materialList {
    display: flex;
    flex-wrap: wrap;
}
.rawMaterialContainer {
    @include flexColumn;
}
.materialCard {
    margin: 8px;
    border-radius: 8px;
    border: 1px solid $secondary-gray;

    &:hover {
        transform: translateY(-2px);
		cursor: pointer;
    }

    .cardImg {
        width: 316px;
        height: 136px;
        opacity: .5;
        object-fit: cover;
        background-color: rgba(0, 0, 0, .5);
        border-radius: 4px 4px 0 0;

        @media (max-width: $mobile-big) {
            width: -webkit-fill-available;
        }
    }

    .cardName {
        height: 36px;
        background: $white;
        border-radius: 0 0 6px 6px;
        padding: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .name {
            color: $secondary-black;
        }
    }
}
.uploaderBox {
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: space-between;
    height: 8rem;
    padding: 2rem;
    border: 1px dashed $secondary-red;
    border-radius: 4px;
    color: $secondary-red;

    max-width: 320px;
    max-height: 50px;

    &:hover {
        border: 2px solid $secondary-red;
        cursor: pointer;
        text-decoration: underline;
    }
}
.link {
    cursor: pointer;
    color: $blue;
}
.legend {
    font-size: 0.75rem;
    letter-spacing: 0.32px;
    display: inline-block;
    margin-bottom: 0.5rem;
    color: #525252;
    font-weight: 400;
    line-height: 1rem;
    vertical-align: baseline;
}
.marginHeightShort {
    margin: 8px 0;
}
.marginHeightMedium {
    margin: 16px 4px;
}
.breadcrumbBox {
    @include breadcrumbBox;
    margin-bottom: 16px;
}
.twoBlocks {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;

    @media (max-width: $mobile-big) {
        flex-direction: column;
    }
}
.contestSideContainer {
    @include flexRow;
    justify-content: space-between;

    @media (max-width: $tablet) {
        @include flexColumn;
        margin: 16px 0;
    }
    @media (max-width: $mobile) {
        width: -webkit-fill-available;
    }
}
.contestStatusBox {
    @include flexRow;
    justify-content: space-between;
    border-bottom: 1px solid $tertiary-gray;
    padding-bottom: 8px;
}
.projectStatus {
    border: 1px solid $secondary-gray;
    border-radius: 4px;
    padding: 8px 16px;
    width: 300px;
    height: max-content;
    margin: 16px 0;

    @media (max-width: $mobile) {
        display: contents;
    }
}
.projectStatusTitle {
    padding-bottom: 8px;
    border-bottom: 1px solid $tertiary-gray;
}
.projectStatusBox {
    padding: 16px 0;
}
.projectDetails {
    @include flexRow;
    justify-content: space-between;
    align-items: center;
    margin: 6px 0;

    a {
        text-decoration: none;
    }
    a:hover {
        text-decoration: underline;
    }
}
.maxSpace {
    width: 600px;

    @media (max-width: $mobile-big) {
        width: -webkit-fill-available;
    }
}
.projectStatusSecondaryAction {
    @include flexRow;
    justify-content: center;

    & > small {
        color: $blue;
    }
}
.blocksListContainer {
    display: flex;
    flex-wrap: wrap;
}
.projectBox {
    @include flexColumn;
}
.statusIcon {
    fill: $tertiary-gray;
    margin: 4px;
}

.callLuis {
    margin-top: 20px;
    border-radius: 12px;
    width: 100%;
    max-width: 400px;
    aspect-ratio: 2;
    background-size: cover;
    background-image: url(../project-feasibility/assets/call-luis.jpg);
    transition: opacity 0.3s;
    cursor: pointer;
}

.callLuis:hover {
    opacity: 0.9;
}
.callLuisText {
    padding-top: 8%;
    padding-left: 8%;
    font-size: 2em;
    width: 50%;
    color: rgb(248, 248, 248);
}

.callLuisLink {
    text-decoration: underline;
    cursor: pointer;
    transition: opacity 0.3s;
    display: inline-block;
}

.callLuisLink:hover {
    opacity: 0.9;
}

.setupCallSide {
    @media (max-width: $mobile-big) {
        width: 100% !important;
        background-color: transparent;
    }
}

.setupCallSide .projectStatus {
    @media (max-width: $mobile-big) {
        width: 100%;
    }
}

.payOptions {
    display: flex;
    gap: 20px;
    width: 600px;

    @media (max-width: $mobile-big) {
        width: -webkit-fill-available;
        display: block;
    }
}

.payOption {
    display: inline-block;
    background-color: rgb(246, 246, 246);
    padding: 20px;
    border-radius: 12px;
    width: 100%;
    position: relative;

    cursor: pointer;
    transition: opacity 0.3s;

    @media (max-width: $mobile-big) {
        margin-bottom: 20px;
    }

    color: #fff;
}

.payOptionTitle {
    font-size: 19px;
}

.payOptionTop {
    display: flex;
    gap: 10px;
    align-items: center;
}

.payOptionPrice {
    opacity: 0.7;
}

.payOptionDescription {
    margin-top: 10px;
    opacity: 0.8;
    margin-bottom: 50px;
    line-height: 1.2;

    @media (max-width: $mobile-big) {
        font-size: 16px;
     }
}

.payOptionButton {
    color: #fff;
    padding: 5px 15px 5px 8px;
    border: 1px solid #fff;
    display: inline-block;
    border-radius: 50px;

    margin-top: 15px;
    bottom: 20px;
    position: absolute;

    @media (max-width: $mobile-big) {
       font-size: 17px;
    }
}

.payOption:hover {
    opacity: 0.9;
}

.payOptionArrow {
    vertical-align: -13px;
}

.payOptionsTitle {
   margin-bottom: 10px;
   max-width: 600px;
}

.payOptionSeparator {
    width: 100%;
    height: 1px;
    background-color: rgb(231, 231, 231);
    border-radius: 10px;
    margin-bottom: 30px;
}