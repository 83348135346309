@import '../../../../../scss/_mixins.scss';

.contentBlock {
    margin: 36px 0;
}
.contentTitle {
    margin-bottom: 16px;
}
.accordion {
    margin: 26px 0;
}
.overviewCategoryBox {
    @include flexRow;
    flex-wrap: wrap;
}
.overviewMessageBox {
    @include overviewMessageBox;
}
.proposalContainer {
	position: relative;
	z-index: 1;
    margin: 8px;
    width: 300px;
    height: 168px;
    border-radius: 4px;
    border: 1px solid $secondary-gray;
    background: $primary-gray;
    transition: all 0.1s ease-in-out;
    object-fit: contain;

    &:hover {
        cursor: pointer;
        transform: scale(1.2);
        box-shadow: 0 6px 16px rgba(0,0,0,.2);
        background: $white;
        object-fit: contain;
        z-index: 2;

		.winnerContainer {
			opacity: 0;
		}
    }
}
.winnerContainer {
	position: absolute;
    top: 8px;
    right: 8px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.1s ease-in-out;
    box-shadow: 0 6px 16px rgba(0,0,0,.2);
}
.thumbnailImg {
	width: 100%;
    height: 100%;
    object-fit: cover;
}

.elementBox {
    @include elementBox;
	justify-content: unset;
}
.naming {
    @include naming;
	flex: 1;
    margin-right: 10px;
    overflow: hidden;
}
.fileName {
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}
.characteristics {
    @include characteristics;
}
.actions {
    @include actions;
    width: 100px;
}
.tab {
    button {
        width: 100% !important;
    }
}
.infoElementsBox {
    @include flexRow;
    align-items: center;
    margin: 36px 16px;

    p {
        margin: 0 8px;
    }
}
.contentBox {
    margin: 36px 0;
}
.accorditionTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
        font-style: italic;
    }
}
.accordionBlock {
    display: flex;
    align-items: center;

    h5 {
        margin: 0 16px;
    }
}
.statusTag {
    border-radius: 4px !important;
    text-transform: uppercase !important;
    font-weight: 600 !important;

    @media (max-width: $mobile) {
        font-size: .5rem !important;
    }
}