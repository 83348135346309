@import '../../../../scss/mixins';

.responsiveContainer {
    @include responsiveContainer;
}
.title {
    @include title;
}
.subtitle {
    margin-top: 8px;
    font-weight: 400;
}
.contentBlock {
    margin: 36px 0;
}
.frameBox {
	display: flex;
	justify-content: center;
}
.formFrame {
	width: 900px;
	height: 600px;

    @media (max-width: $mobile-big) {
        width: -webkit-fill-available;
    }
}
.packagesList {
    display: flex;
    flex-wrap: wrap;
    margin: 36px 0;
}
.package {
    width: 400px;
    min-height: 400px;
    height: -moz-fit-content;
    height: fit-content;
    margin: 0 8px;
    padding: 36px;
    background: $primary-gray;
    border: 2px solid $primary-gray;
    border-radius: 16px;
    transition: all .3s;
    cursor: pointer;

    &:hover {
        border: 2px solid $main-red;
    }

    @media (max-width: $tablet) {
        width: -webkit-fill-available;
        margin: 8px 0;
    }
}
.titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px dashed $tertiary-gray;
    padding-bottom: 16px;
}
.packageTitle {
    color: $main-red;
    transition: all .3s;
}
.flexColumn {
    @include flexColumn;
}
.selectIcon {
    width: 48px;
    height: 48px;
}
.returnAction {
    @include flexRow;
    align-items: center;
    cursor: pointer;
    padding: 4px;
    width: 78px;
    height: 40px;
    border-radius: 4px;

	&:hover {
        background: $primary-gray;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .08);
	}

    span {
        color: $secondary-red;
    }
}
.bottomBtnContainer {
    display: flex;
    justify-content: flex-end;

    @media (max-width: $tablet) {
        justify-content: center;
    }
}
.selectedPackageBlock {
    @include flexColumn;
    align-items: center;
    margin: 16px;
    background: $primary-gray;
    border-radius: 8px;
    padding: 16px;
    z-index: 1;
}
.successColor {
    color: $green;
}
.pendingColor {
    color: $tertiary-gray;
}
.btn {
    @include btnWithIcon;
}
.link {
    cursor: pointer;
    color: $blue;

    &:hover {
        text-decoration: underline;
    }
}