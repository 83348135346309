@import '../../../scss/mixins';

.filesContainer {
    @include responsiveContainer;
}
.title {
    @include title;
}
.subtitle {
    margin-top: 8px;
    font-weight: 400;
}
.content {
    margin: 36px 0;
}
.elementBox {
    @include elementBox;

    @media (max-width: $mobile) {
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: -webkit-fill-available;
        margin: 8px 0;
    }
}
.naming {
    @include naming;
}
.fileName {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.characteristics {
    @include characteristics;
}
.actions {
    @include actions;
    width: 100px;

    @media (max-width: $mobile) {
        margin: 6px 0px;
    }
}
.moreOptions {
    width: 176px;
    justify-content: flex-end;
}
.statusTag {
    border-radius: 4px !important;
    text-transform: uppercase !important;
    letter-spacing: 1px !important;
    font-weight: 600 !important;

    @media (max-width: $mobile) {
        width: -webkit-fill-available;
    }
}
.infoTag {
    border-radius: 12px !important;
    text-transform: uppercase !important;
    letter-spacing: 1px !important;
    font-weight: 600 !important;

    @media (max-width: $mobile) {
        width: -webkit-fill-available;
    }
}
.accorditionTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
        font-style: italic;
    }
    @media (max-width: $mobile) {
        flex-direction: column;
    }
}
.contestTitle {
    text-transform: capitalize;

    @media (max-width: $mobile) {
        font-size: 1.2rem;
    }
}
.accordionBlock {
    display: flex;
    align-items: center;

    h4 {
        margin: 0 16px;
    }
}
.contestDetailsBlock {
    @include flexRow;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: $mobile) {
        flex-direction: column;
    }
}
.phaseBubble {
    width: 36px;
    height: 36px;
    background: $secondary-gray;
    border-radius: 50%;
    @include flexRow;
    @include centeredItems;
    margin: 4px;
}
.btn {
    @include btnWithIcon;
}
.link {
   text-decoration: none;
}