@import '../../scss/_mixins.scss';

.header {
    background-color: $white !important;
    border-bottom: 1px solid $secondary-gray !important;
    height: 55px !important;

    @media (max-width: $desktop) {
		justify-content: space-between !important;
        position: fixed !important;
	}
}
.logo {
    width: 80px;
    height: auto;
}
.avatarIcon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid rgb(238, 238, 238);
}
.secondaryAction {
    height: auto !important;

    &:hover {
        background-color: $primary-gray !important;
    }
}
.menuOpenIcon {
    > svg {
        fill: $secondary-black !important;
    }
}
.switcher {
    position: fixed;
    background: $white;
    right: 8px;
    top: 72px;
    z-index: 1000;
    border: 1px solid $primary-gray;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 8px 22px rgba(0, 0, 0, .2);
}
.menuOptions {
    @include flexColumn;
    background: $white;
    border-radius: 4px;
}
.optionItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 16px 8px;

    &:hover {
        background: #f7f7f7;
    }
}
.iconMenu {
    cursor: pointer;
	display: flex;
	align-items: center;
	margin: 0 8px;
	padding: 8px;
    border-radius: 8px;
    background: #f7f7f7;
}
.userName {
    padding: 8px;
    color: $primary-black;
    border-bottom: 1px solid $primary-gray;
    @include flexColumn;
    user-select: none;

    small {
        color: $secondary-black;
        font-size: small;
    }
}
.sideNavLink {
    text-decoration: none;
    color: $secondary-black;
    transition: color 0.1s;

    &:hover {
        color: $primary-black;
    }
}
.rightNavAction {
    max-width: auto;
    margin-top: 7px;
    @media (max-width: $tablet) {
		max-width: 48px !important;
	}
}
.menuItemContainer {
    position: relative;
    display: flex;
}
.menuItemContainer li {
    list-style-type: none;
}
.headerMenuLink {
    background-color: #fff;
}
.headerMenuLink a {
    cursor: pointer;
    transition: color 0.1s;
    font-weight: 400 !important;
    color: rgb(156, 156, 156) !important;
}
.headerItem {
    color: $tertiary-gray;

    &:active {
        color: $main-red;
        border-bottom: 1px solid $main-red;
    }
}
.lastAction {
    border-top: 1px solid $primary-gray;

    > a:hover {
        background: $main-red !important;
    }
}
.lastNavbarBlock {
    border-top: 1px solid $primary-gray;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 4px;

    > a > small {
        color: $tertiary-gray;
    }
    > a > small:hover {
       cursor: pointer;
       color: $tertiary-black;
    }
    > a {
        text-decoration: none;
    }
    > a:hover {
        text-decoration: underline;
    }
}
.newAction {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.rightNavLink {
    color: $tertiary-gray;
    width: 86px;
    font-size: 14px;
    font-weight: 600;
    transition: color 0.1s;

    &:hover {
        color: $primary-black;
    }
}
.alphaTag {
    position: absolute;
    top: 6px;
    right: -4px;
    border-radius: 4px !important;
    min-height: 2px !important;
    height: 9px;
    font-size: 6px !important;
    z-index: 9000;
}
.onShake {
    animation: shake 0.5s;
    animation-iteration-count: 5s;
}
@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.newProjectWide {
    display: inline;
} 
.newProjectNarrow {
    display: none;
}
@media (max-width: $mobile-big) {
    .newProjectWide {
        display: none;
    } 
    .newProjectNarrow {
        display: inline;
    }
}

.headerNavigation {
    margin-left: -20px;
}
.headerNavigation:before {
    background-color: rgb(223, 223, 223);
    background-color: transparent !important;
}
.inviteBtn {
    @media (max-width: $mobile-big) {
		display: none !important;
	}
}
.referral {
    background: #017cf6;
    padding: 6px;
    display: flex;
    border-radius: 4px;

    &:hover {
        opacity: 0.8;
    }
}