@import '../../scss/_mixins.scss';

.stateContainer {
    position: relative;
    @include flexRow;
    flex-wrap: nowrap;
    justify-content: center;
}
.progressContainer {
    @include flexColumn;
    justify-content: space-between;
    height: 172px;
}
.boxIcon {
    position: absolute;
    svg {
        cursor: pointer;
    }
}
.stateCircleContainer {
    @include flexRow;
    align-items: center;
    position: relative;
}
.circle {
    margin: 0px 8px;
    border-radius: 50%;
    width: 86px;
    height: 86px;
    @include flexRow;
    @include centeredItems;
	cursor: pointer;

    &:hover {
        box-shadow: 0 4px 16px rgba(0,0,0,.16);
        transform: translateY(-1px);
    }
}
.phaseStep {
    width: 96px;
    color: $primary-black;
    text-align: center;
    text-transform: capitalize;
}
.active {
    font-weight: 600;
}
.tag {
    border-radius: 4px !important;
    margin: 8px 0 !important;
}
.bubblePhase {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 112px;
}
.phaseStateName {
    @include flexColumn;
    align-items: center;
    position: absolute;
    bottom: -20px;

    padding: 0 16px;
    background-color: $white;
    z-index: 10;
    border-radius: 8px 8px 0 0;
}
.phaseBubble {
    width: 76px;
    height: 76px;
    background: $secondary-gray;
    border-radius: 50%;
    @include flexRow;
    @include centeredItems;
    color: $white;
    font-weight: 600;
    margin: 4px;
    font-size: 18pt;
    cursor: pointer;
}
.activePlus {
    fill: $tertiary-black !important;
}
.phaseBubbleProgressBar {
    width: 92px !important;
    height: 92px !important;
    margin: 0px 8px !important;
}
.secondaryPhaseBubbleIcons {
    position: absolute;
    bottom: -4px;
    z-index: 10;
    background: $white;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    @include flexRow;
    @include centeredItems;

    svg {
        opacity: 1;
    }
}
.contestStatesBox {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.contestSetUpBox {
    position: absolute;
    bottom: -44px;
    left: 135px;
    padding: 8px;
    width: 130px;
    border-width: 1px;
    text-align: center;
    border-style: none solid solid;
    border-radius: 0 0 8px 8px;
    border-color: rgb(225, 228, 232);
    background: $white;
    z-index: 2;
    cursor: pointer;

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px, rgba(0, 0, 0, 0.3) 0px 4px 28px;
        background: $primary-gray;
    }
}
.lineBottom {
    position: relative;
    bottom: -19px;
    border-bottom: 1px solid $secondary-gray;
}