@import '../../../../scss/mixins';

.additionalButtons {
	& svg {
		max-width: 20px;
		max-height: 20px;
	}
}
.tabContentContainer {
    margin: 16px 0;
}
.title {
    @include title;
}
.contentTitle {
    margin-bottom: 16px;
}
.elementBox {
    @include elementBox;
    margin: 0;
    padding: 12px;
    border-radius: 12px;

    @media (max-width: $mobile-big) {
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: -webkit-fill-available;
    }
}
.proposalsContainer {
	display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 16px 0;
}
.disabledOption {
    overflow-x: hidden;
    background: $white;
    position: relative;
}
.disabledMessage {
    position: absolute;
    top: 36%;
    bottom: 36%;
    right: 32%;
    left: 32%;
    z-index: 10;
    background: $white;
    padding: 16px 36px;
    min-height: 76px;
    min-width: 82px;
    border-radius: 8px;
    text-align: center;
    opacity: 0.95;

    @media (max-width: $mobile-big) {
        right: 0;
        left: 0;
    }
}
.winningProposal {
    background: $primary-gray;
    border: 1px solid $primary-gray;
}
.naming {
    @include naming;

    svg {
        margin-right: 10px;
    }
}
.fileName {
    width: 136px;
}
.updateBox {
    @include updateBox;
}
.notification {
    width: 100%;

    @media (max-width: $mobile) {
        max-width: -webkit-fill-available !important;
    }
}
.btn {
    display: inline !important;
    border-radius: 4px !important;
    padding: 0 !important;
    text-align: center !important;
    width: -webkit-fill-available !important;

    @media (max-width: $mobile) {
        max-width: -webkit-fill-available !important;
    }
}
.fileBtn {
    width: 180px !important;
    min-height: 2.5rem !important;
}
.characteristics {
    @include characteristics;
    justify-content: space-between;

    @media (max-width: $mobile) {
        display: none;
    }
}
.inputCharBox {
    @include inputCharBox;
}
.actions {
    @include actions;
}
.fileAction {
    width: 100px;
}
.moreOptions {
    width: 260px;
    justify-content: flex-end;
}
.message {
    @include flexRow;
    align-items: center;
    svg {
        margin-right: 8px;
    }
}
.loadingBox {
    @include flexRow;
    @include centeredItems;

    p {
        vertical-align: middle;
        padding-left: .5rem;
        padding-right: .5rem;
    }
}
.uploadContainer {
    @include flexRow;
    justify-content: center;
}
.uploadContainerPreview {
    @include flexColumn;
    justify-content: center;
    align-items: center;
}
.previewImgFile {
    width: 500px;
    height: auto;
    margin: 16px;
    filter: drop-shadow(2px 4px 6px black);

    @media (max-width: $mobile) {
        width: -webkit-fill-available;
    }
}
.uploadBlock {
    @include flexColumn;

    a {
        margin: 16px 0;
        cursor: pointer;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}
.uploadBox {
    width: 600px;
    height: 350px;
    background: $primary-gray;
    border-radius: 8px;
    @include flexRow;
    @include centeredItems;
    cursor: pointer;

    &:hover {
        background: $secondary-gray;
    }

    @media (max-width: $mobile) {
        width: 80vw;
        height: 126px;
    }
}
.checkbox {
    @include flexRow;
    justify-content: center;
        
    div {
        @include flexRow;
        justify-content: center;
    }
    label {
        cursor: auto !important;

        &::before {
            background: $secondary-red !important;
            border-color: $secondary-red !important;
        }
    }
}
.checkboxInline {
    div {
        flex: none;
    }
}
.warningIcon {
    color: $yellow;
}
.loadingIcon {
    width: 32px !important;
    height: 32px !important;
}
.toastNotification {
    position: fixed;
    top: 50px;
    right: 50px;
}

.modalContent {
    padding: 0 36px;
    width: 100%;

    .contentElementsBox {
        @include flexColumn;
        margin: 16px 0;

        p {
            margin: 16px;
        }
    }
}

.statusTag {
    border-radius: 12px !important;
    text-transform: uppercase !important;
    letter-spacing: .5px !important;
    font-weight: 600 !important;
}

.updates {
    @include flexRow;

    svg {
        margin-right: 8px;
    }
}
.overviewMessageBox {
    @include overviewMessageBox;
    margin: 16px;
}
.phaseBubble {
    width: 36px;
    height: 36px;
    background: $secondary-gray;
    border-radius: 50%;
    @include flexRow;
    @include centeredItems;
    color: $white;
    font-weight: 600;
    margin: 4px;
}

.proposalContainer {
	position: relative;
	z-index: 1;
    margin: 8px;
    width:  318px;
    height: 212px;
    border-radius: 8px;
    border: 1px solid #DDDDDD;
    background: $primary-gray;
    transition: all 0.1s ease-in-out;
    object-fit: contain;

    &:hover {
        cursor: pointer;
        // transform: scale(1.2);
        // box-shadow: 0 6px 16px rgba(0,0,0,.2);
        background: $white;
        object-fit: contain;
        z-index: 2;

		.winnerContainer {
			opacity: 0;
		}
    }
}
.phaseTag {
	position: absolute;
    top: 8px;
    right: 8px;
	display: flex;
	align-items: center;
    justify-content: center;
	color: $white !important;
}
.winnerContainer {
	position: absolute;
    top: 8px;
    right: 8px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: $white;
    display: flex;
	align-items: center;
    justify-content: center;
    transition: all 0.1s ease-in-out;
    box-shadow: 0 6px 16px rgba(0,0,0,.2);
}
.lockedContainer {
	position: absolute;
    top: 4rem;
    right: 7rem;
    left: 7rem;
    bottom: 4rem;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: $white;
    display: flex;
	align-items: center;
    justify-content: center;
    transition: all 0.1s ease-in-out;
    box-shadow: 0 6px 16px rgba(0,0,0,.2);
}
.winningProposalImg {
	width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}
.imgContainer {
    display: flex;
    justify-content: center;
}
.proposalImg {
    max-height: 300px;
    border-radius: 4px;
    margin: 16px 0;
    cursor: pointer;
}
.flexColumn {
    @include flexColumn;
}
.tooltip {
    margin: 16px;

    &:hover {
        cursor: pointer;
        color: $blue;
    }
}
.fileUploaderContainer {
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: space-between;
    height: 8rem;
    padding: 2rem;
    border: 1px dashed $secondary-red;
    border-radius: 4px;
    color: $secondary-red;

    &:hover {
        border: 2px solid $secondary-red;
        cursor: pointer;
        text-decoration: underline;
    }
}
.loadMore {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 16px;
}
.link {
    @include link;
}

// Pending feasibility block
.twoBlocks {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: $mobile-big) {
        flex-direction: column;
    }
}
.contestSideContainer {
    @include flexRow;
    justify-content: space-between;

    @media (max-width: $tablet) {
        @include flexColumn;
        margin: 16px 0;
    }
    @media (max-width: $mobile) {
        width: -webkit-fill-available;
    }
}
.contestStatusBox {
    @include flexRow;
    justify-content: space-between;
    border-bottom: 1px solid $tertiary-gray;
    padding-bottom: 8px;
}
.contentBlock {
    margin: 36px 0;
}
.maxSpace {
    width: 600px;

    @media (max-width: $mobile-big) {
        width: -webkit-fill-available;
    }
}
.callLuis {
    margin-top: 20px;
    border-radius: 12px;
    width: 100%;
    max-width: 400px;
    aspect-ratio: 2;
    background-size: cover;
    background-image: url(./assets/call-luis.jpg);
    transition: opacity 0.3s;
    cursor: pointer;
}
.callLuis:hover {
    opacity: 0.9;
}
.callLuisText {
    padding-top: 8%;
    padding-left: 8%;
    font-size: 2em;
    width: 50%;
    color: rgb(248, 248, 248);
}
.callLuisLink {
    text-decoration: underline;
    cursor: pointer;
    transition: opacity 0.3s;
    display: inline-block;
}
.callLuisLink:hover {
    opacity: 0.9;
}
.contentTitl {
    @include contentTitle;
}
.projectBox {
    @include flexColumn;
}
.statusIcon {
    fill: $tertiary-gray;
    margin: 4px;
}
.setupCallSide {
    @media (max-width: $mobile-big) {
        width: 100% !important;
        background-color: transparent;
    }
}
.projectStatusBox {
    padding: 16px 0;
}
.projectDetails {
    @include flexRow;
    justify-content: space-between;
    align-items: center;
    margin: 6px 0;

    a {
        text-decoration: none;
    }
    a:hover {
        text-decoration: underline;
    }
}
.projectStatus {
    border: 1px solid $secondary-gray;
    border-radius: 4px;
    padding: 8px 16px;
    width: 300px;
    height: max-content;

    @media (max-width: $mobile) {
        display: contents;
    }
}
.setupCallSide .projectStatus {
    @media (max-width: $mobile-big) {
        width: 100%;
    }
}
.blocksListContainer {
    display: flex;
    flex-wrap: wrap;
}
.payOptions {
    display: flex;
    gap: 20px;
    width: 600px;

    @media (max-width: $mobile-big) {
        width: -webkit-fill-available;
        display: block;
    }
}
.payOption {
    display: inline-block;
    background-color: rgb(246, 246, 246);
    color: $white;
    padding: 20px;
    border-radius: 12px;
    width: 100%;
    position: relative;
    cursor: pointer;
    transition: opacity 0.3s;

    @media (max-width: $mobile-big) {
        margin-bottom: 20px;
    }
}
.payOptionTitle {
    font-size: 19px;
}
.payOptionTop {
    display: flex;
    gap: 10px;
    align-items: center;
}
.payOptionPrice {
    opacity: 0.7;
}
.payOptionDescription {
    margin-top: 10px;
    opacity: 0.8;
    margin-bottom: 50px;
    line-height: 1.2;

    @media (max-width: $mobile-big) {
        font-size: 16px;
     }
}
.payOptionButton {
    color: $white;
    padding: 5px 15px 5px 8px;
    border: 1px solid $white;
    display: inline-block;
    border-radius: 50px;
    margin-top: 15px;
    bottom: 20px;
    position: absolute;

    @media (max-width: $mobile-big) {
       font-size: 17px;
    }
}
.payOption:hover {
    opacity: 0.9;
}
.payOptionArrow {
    vertical-align: -13px;
}
.payOptionsTitle {
    margin-bottom: 10px;
    max-width: 600px;
}
.payOptionSeparator {
    width: 100%;
    height: 1px;
    background-color: rgb(231, 231, 231);
    border-radius: 10px;
    margin: 36px 0;
}
.tooltipText > button {
    font-weight: 600 !important;
    font-size: 14px !important;
}
.phaseBubble {
    width: 20px;
    height: 20px;
    background: $secondary-gray;
    border-radius: 50%;
    @include flexRow;
    @include centeredItems;
    color: $white;
    font-weight: 600;
    margin: 4px;
    font-size: 8pt;
}
.link {
    cursor: pointer;
	display: flex;
	align-items: center;
	margin: 0 8px;
	padding: 8px;

	&:hover {
		border-radius: 8px;
        background: #F7F7F7;
	}
}
