@import '../../../scss/_mixins.scss';

.projectList {
    width: 100%;
    @include responsiveContainer;
}
.goBackContainer {
    margin-bottom: 30px;
}
.title {
    @include title;
}
.subtitle {
    margin-bottom: 16px;
}
.filtersContainer {
    margin: 16px 0;

    span {
        cursor: pointer;
    }
}
.switcher {
    color: $tertiary-gray;
    text-decoration: underline;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}
.littleText {
    color: $tertiary-black;
	font-size: 0.75rem;
	letter-spacing: 0.32px;
	margin-bottom: 16px;
}
.secondaryBtnContainer {
    @include flexColumn;
    flex-wrap: wrap;
    margin: 36px 0;
}
.experienceBlock {
    @include flexRow;
    justify-content: flex-start;

    @media (max-width: $mobile-big) {
        justify-content: center;
        width: -webkit-fill-available;
    }
}
.divider {
    border-left: 1px solid $secondary-gray;
    margin: 0 16px;
}
.activityContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: $mobile-big) {
        justify-content: center;

        a {
            margin: 16px 0;
        }
    }
}
.bigHighlightedBlock {
	max-width: 1008px;
	min-height: 236px;
	max-height: 336px;
    background: $primary-gray;
    border-radius: 12px;
	display: flex;
	justify-content: space-between;
	color: $white;
	box-shadow: 0 6px 16px rgba(0,0,0,.2);
	margin-bottom: 16px;

	@media (max-width: $tablet) {
		flex-wrap: wrap;
		max-height: fit-content;
	}
}
.btn {
	@include btnPrimary;
	width: 200px !important;
}
.bottomBtnsContainer {
	display: flex;
	justify-content: flex-end;

	@media (max-width: $mobile-big) {
		flex-wrap: wrap;
		justify-content: center;

		> button {
			margin: 8px;
		}
	}
}
.highlightImg {
	max-width: 400px;
    min-width: 400px;
	width: -webkit-fill-available;
    height: 100%;
	min-height: auto;
    object-fit: cover;
    border-radius: 12px 0 0 12px;

	@media (max-width: $tablet) {
		max-width: -webkit-fill-available;
		width: -webkit-fill-available;
		border-radius: 12px 12px 0 0;
		max-height: 236px;
        min-width: 236px;
	}
}
.flatTag {
    width: 116px;
    border-radius: 4px !important;
    min-height: 1rem !important;
    height: 20px;
    margin: 0 !important;
}
.leftHighlightedBlock {
	position: relative;
	@media (max-width: $tablet) {
        width: -webkit-fill-available;
    }
}
.rightHighlightedBlock {
	padding: 36px;
    width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    user-select: none;

	@media (max-width: $mobile-big) {
        padding: 36px 16px;
		max-height: fit-content;
    }
}
.featuredProjectContent {
	display: flex;
	justify-content: space-between;

	@media (max-width: $mobile) {
		flex-direction: column;
		margin-bottom: 16px;
	}
}
.contributorsList {
    display: flex;
    margin-top: 8px;
}
.phaseLines {
    width: 36px;
    height: 46px;
    margin-right: 4px;
    border-radius: 6px;
    @include flexColumn;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    user-select: none;
    cursor: pointer;
    transition: width 0.1s;

    span {
        color: $white;
        font-size: 0.7rem;
    }
    h6 {
        color: $white;
    }
}

.phaseLines:hover {
    flex-direction: column-reverse;
    width: 126px;

    span {
       opacity: 0;
    }
    h6 {
        opacity: 0;
    }
}

.openPhase {
    span {
        opacity: 1!important;
     }
     h6 {
         opacity: 1!important;
     }
}

.extraContainer {
    display: flex;
    flex-wrap: wrap;
}
.contestOption {
    padding: 16px;
    border: 1px dashed $secondary-gray;
    border-radius: 12px;
    display: flex;
    justify-content: center;

    > small {
        color: $tertiary-gray;
        &:hover {
            font-size: 1rem;
        }
    }
}
.listItem {
    display: flex;
    align-items: center;
    margin: 8px;
}
.iconFilled {
    fill: $green;
    margin-right: 8px;
}
.iconPending {
    fill: $tertiary-gray;
    margin-right: 8px;
}
.linkText {
    color: $quaternary-gray;

    &:hover {
        color: $blue;
        cursor: pointer;
        text-decoration: underline;
    }
}

.linkTextAction {
    color: $quaternary-gray;
    margin-bottom: 1px;
    border-bottom: 1px solid $quaternary-gray;

    &:hover {
        color: $blue;
        cursor: pointer;
        border-bottom: 1px solid $blue;
    }
}

.linkTextDisabled {
    color: $quaternary-gray;
    margin-bottom: 1px;
    border-bottom: 1px solid transparent;
}

.listContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.profileHeaderBlock {
    margin-right: 36px;

    @media (max-width: $mobile-big) {
        margin-bottom: 16px;
        margin-left: 8px;
    }
}
.completinessBlock {
    display: flex;
    flex-wrap: wrap;
}
.circleLocked {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    padding: 8px;
    opacity: 0.5;

    &:hover {
        background: $tertiary-black;
        opacity: 1;

        > svg {
            fill: $white !important;
        }
    }
}