.pager {
	display: flex;
	justify-content: center;
	margin-top: 20px;
	margin-bottom: 50px;
	opacity: 1;
}

.step {
	display: flex;
	align-items: center;
	gap: 10px
}

.bubble {
	height: 29px;
	width: 29px;
	font-size: 15px;
	background-color: #00A2FF;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
	color: #fff;
}

.gray {
	background-color: #d5d5d55e;
	color: #939393;
}

.name {
	font-size: 15px;
}

.nameGray {
	color: #939393;
}

.separator {
	height: 1px;
	margin-right: 10px;
	width: 30px;
	background-color: #d5d5d55e;
}
