@import '../../scss/mixins';

.returnActionModal {
    @include flexRow;
    align-items: center;
    margin-bottom: 16px;
	position: absolute;

	&:hover {
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .08);
	}

    span {
        color: $secondary-red;
    }
}
.modalContent {
    padding: 0 36px;
}
.listModal {
    padding: 0 16px;
    margin: 16px;
    list-style-type: disc;

    li  {
        margin: 12px 0;
    }
}
.contestContent {
    margin: 16px 0;
    p {
        padding: 4px 16px;
    }
}
.contestImgContainer {
	position: relative;
	cursor: pointer;
	margin: 8px 16px;
	
	&:hover {
		.contestImgOverlay {
			opacity: .8;
		}
	}
}
.contestImg {
	width: 200px;
    height: 125px;
    border-radius: 4px;
	object-fit: cover;
}
.contestImgOverlay {
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background: $primary-black;
	color: $white;
	opacity: 0;
	display: flex;
    align-items: center;
	border-radius: 4px;
	
	div {
		display: flex;
		align-items: center;
		text-align: center;
		justify-content: center;
		width: 100%;
		text-align: center;
		font-size: 18px;
		
		svg {
			max-width: 20px;
			margin-right: 5px;
		}
	}
}
.link {
    cursor: pointer;
    color: $blue;
}
.btn {
    @include btnPrimary;
    width: 200px !important;
    margin: 8px 0 !important;
}
.aBtn {
    padding: 8px !important;
}
.ccardBtn {
	display: flex;
	justify-content: center;
	margin: 16px 0;
}
.listSpecialAttention {
    padding: 16px 36px;

    > ul {
        list-style: circle;
    }
}