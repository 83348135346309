@import '../../scss/_mixins.scss';

.benefitContainer {
	position: relative;
	background: $primary-gray;
	border-radius: 12px;
	width: 326px;
	height: 206px;
	padding: 16px;
	margin: 0 16px 16px 0;
	@include flexColumn;
    justify-content: flex-start;
}
.content {
	max-width: -webkit-fill-available;
	block-size: fit-content;
}
.name {
	margin: 8px 0;
}
.icon {
	> svg {
		width: 48px;
		height: 48px;
	}
}