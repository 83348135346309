@import '../../../scss/mixins';

.projectViewPage {
    @include responsiveContainer;
	width: 100%;
}
.secondaryTitle {
	font-size: 22px;
}
.titleBox {
    @include flexColumn;

    @media (max-width: $mobile-big) {
        max-width: 268px;
    }
}
.titleSpace {
    margin-bottom: 4px;
}
.loadingContainer {
    width: -webkit-fill-available;
	margin: 0px auto;
}
.betweenBlock {
	display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.cardImage {
    width: 100%;
    height: 342px;
    object-fit: cover;
    border-radius: 4px;
    // filter: blur(.5px);
	background: #eee;

    @media (max-width: $mobile) {
        height: auto;
    }
}
.imgSkeleton {
    width: 100% !important;
    height: 350px !important;
    border-radius: 8px;

    @media (max-width: $mobile) {
        height: 200px !important;
    }
}
.secondaryImgSkeleton {
    height: 171px !important;
    width: 264px !important;
}
.proposalSkeleton {
    width: 300px !important;
    height: 200px !important;
    margin: 8px;
}
.secondaryBtnContainer {
    @include flexColumn;
    flex-wrap: wrap;
    margin: 36px 0;
}
.experienceBlock {
    @include flexRow;
    justify-content: flex-start;

    @media (max-width: $mobile-big) {
        justify-content: center;
        width: -webkit-fill-available;
    }
}
.divider {
    border-left: 1px solid $secondary-gray;
    margin: 0 16px;
}
.horizontalDivider {
	border-top: 1px solid $quinternary-gray;
	border-radius: 2px;
	margin: 36px 0;
}
.activityContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: $mobile-big) {
        justify-content: center;

        a {
            margin: 16px 0;
        }
    }
}
.containerSkeleton {
    margin: 36px 0;
}
.projectInfoContainer {
    @include flexRow;
    width: -webkit-fill-available;
    gap: 36px;

    @media (max-width: $tablet) {
        flex-direction: column;
        margin: 0;
        gap: 0;
    }
}
.infoContainer {
    width: 100%;
}
.tags {
    @include flexRow;
    flex-wrap: wrap;
    gap: 10px;
    padding-right: 0px;
}

.tag {
    color: #313131;
    background-color: #F3F3F3;
    border-radius: 4px;
    padding: 6px 9px
}

.sideInfo {
    position: sticky;
    display: inline-block;
    position: -webkit-sticky;
    -ms-flex-item-align: start;
    align-self: flex-start;
    top: 86px;

    @media (max-width: $tablet) {
        position: initial;
        align-self: initial;
    }
}
.btn {
    display: inline !important;
    border-radius: 16px !important;
    padding: 0 !important;
    text-align: center !important;
    width: -webkit-fill-available !important;
    max-width: 150px !important;

    @media (max-width: $tablet) {
        max-width: -webkit-fill-available !important;
    }
}
.chatBtn {
    background-color: $blue !important;

    @media (max-width: $mobile-big) {
        margin-top: 16px !important;
    }
}
.large {
    max-width: 200px !important;
}
.inlineLoading {
    justify-content: center;
}
.notification {
    min-width: 100% !important;
    width: 100% !important;
    margin-bottom: 0 !important;
}
.link {
    cursor: pointer;
	display: flex;
	align-items: center;
	margin: 0 8px;
	padding: 8px;

	&:hover {
		border-radius: 8px;
        background: #F7F7F7;
	}
}
.secondaryLink {
    @include link;
}
.projectActionBtns {
    @media (max-width: $mobile-big) {
        display: none !important;
    }
}
.imagesGrid {
    display: grid;
    width: 100%;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(4, 1fr);
    grid-auto-flow: dense;
    column-gap: 8px;
    row-gap: 8px;
  }
.imagesGrid > *:first-child {
    grid-column: span 2;
    grid-row: span 2;
}
.photosAction {
    position: absolute;
    bottom: 16px;
    right: 16px;
    background: $white;
    border-radius: 8px;
    padding: 4px 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: 1px solid $secondary-black;

    &:hover {
        background: #F7F7F7;
    }
}
.setUpPhotosAction {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    background: $white;
    border-radius: 8px;
    padding: 4px 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: 1px solid $secondary-black;

    &:hover {
        background: #F7F7F7;
    }
}
.projectMainImg {
    width: 100%;
    min-width: 526px;
    min-height: 342px;
    height: -webkit-fill-available;
    object-fit: cover;
    border-radius: 8px 0 0 8px;
	background: $primary-gray;

    @media (max-width: $mobile) {
        height: auto;
    }
}
.secondaryProjectImg {
    min-height: 172px;
    max-height: 172px;
    min-width: 258px;
    width: 100%;
    object-fit: cover;
    border: 1px solid #f7f7f7;

    &:nth-child(3) {
        border-radius: 0 8px 0 0;
    }
    &:nth-child(5) {
        border-radius: 0 0 8px 0;
    }
}
.contestsContainer {
    display: flex;
    flex-wrap: wrap;
    margin: 16px 0;
    gap: 16px;

    @media (max-width: $mobile-big) {
        display: block;
    }
}
.contestBox {
    border: 1px solid $quinternary-gray;
    border-radius: 12px;
    max-width: 326px;
    min-width: 326px;
    height: 100px;
    display: flex;
    justify-content: flex-start;
    padding: 26px 20px;
    // box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px;

    &:hover {
        box-shadow: 0 2px 10px rgba(242, 242, 242, 1);
        opacity: .95;
    }
    @media (max-width: $mobile-big) {
        margin: 16px 0;
        max-width: 100%;
        min-width: 286px;
        width: 100%;
    }
}
.contestList {
    border: none;
    background: #FAFAFA;
    flex-direction: column;
    padding: 0px;
    height: fit-content;
    justify-content: space-between;
}
.contestContent {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}
.contestBlockInfo {
    @include flexColumn;
    align-items: flex-end;
    width: max-content;
}
.spaceBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.flexColumn {
    @include flexColumn;
}
.setUpBox {
    background-color: rgb(246, 246, 246);
    min-height: 68px;
    height: auto;
    @include flexColumn;
    justify-content: center;
    padding: 16px;
    margin: 36px 0px;
    border-radius: 8px;
}
.contestProposalsContainer {
    display: flex;
    justify-content: center;
    width: -webkit-fill-available;
    background: #D9D9D912;
    border-radius: 8px;
    height: 200px;
    border: 1px dashed #dcdcdc;
}
.lockContainer {
	@include flexColumn;
    @include centeredItems;
	align-items: center;
}

.lockedIcon {
    margin-bottom: 10px;
    font-size: 30px;
    color: #808080;
}

.lockContainer p {
	align-items: center;
    color: #313131;
    max-width: 300px;
    text-align: center;
    line-height: 1.2;
}
.contentBlock {
    margin: 16px 0;
}
.inventorCharacteristics {
    border: 1px solid $secondary-gray;
    border-radius: 4px;
    padding: 8px 16px;
    width: 326px;
    @include flexColumn;

    @media (max-width: $tablet) {
        display: contents;
    }
}
.allImagesContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: $primary-gray;
    z-index: 8000;
    overflow-y: scroll;
}
.allImagesContent {
    @include responsiveContainer;
    margin-top: 3rem;
    margin-bottom: 3rem;
}
.projectMainImgConfig {
    width: 100%;
    // max-width: 526px;
    max-width: -webkit-fill-available;
    height: 300px;
    object-fit: cover;
    border-radius: 8px;
	background: $primary-gray;

    @media (max-width: $mobile) {
        height: auto;
    }
}
.materialList {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin: 16px 0;
}
.rawMaterialContainer {
    @include flexColumn;
}
.materialCard {
    border-radius: 8px;
    border: 1px solid $secondary-gray;

    &:hover {
		cursor: pointer;
    }

    .cardImg {
        width: 316px;
        height: 136px;
        opacity: .8;
        object-fit: cover;
        background-color: rgba(0, 0, 0, .5);
        border-radius: 4px 4px 0 0;

        @media (max-width: $mobile-big) {
            width: -webkit-fill-available;
        }
    }

    .cardName {
        height: 36px;
        background: $white;
        border-radius: 0 0 6px 6px;
        padding: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .name {
            color: $secondary-black;
        }
    }
}
.uploaderBox {
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: space-between;
    height: 8rem;
    padding: 2rem;
    border: 2px dashed $secondary-red;
    border-radius: 4px;
    color: $secondary-red;

    max-width: 320px;
    max-height: 50px;

    &:hover {
        border: 2px solid $secondary-red;
        cursor: pointer;
        text-decoration: underline;
    }
}
.newIn {
    position: absolute;
    top: 16px;
    right: 16px;
    border-radius: 16px;
    z-index: 3;
    box-shadow: 0 6px 16px rgba(0,0,0,0.12);
    padding: 8px 16px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.03em;
    line-height: 14px;
    background: #0d0c22;
    color: $white;
    opacity: 0.7;
}
.overflowMenu {
    > svg {
        height: 14px;
        width: 14px;
    }
    &:hover {
		border-radius: 8px;
        background-color: #F7F7F7 !important;
	}
}
.shareMessageBox {
    z-index: 5;
    width: 246px;
    height: auto;
    padding: 16px 26px;
    border-radius: 8px;
    text-align: left;
}
.alternativeTitle {
    text-transform: uppercase;
    font-weight: 400;
    color: #B9B9B9;
    font-size: 14px;
    margin-top: 5px;
}
.bottomText {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 10px;

    > span {
        color: #C7C7C7;
    }
}

.projectTopStatuses {
    display: flex;
    gap: 35px;
    padding-left: 8px;
    font-size: 14px;
}

.projectTopStatusTitle {
    color: #898989;
    margin-bottom: 3px;
    font-size: 12px;
}

.projectTopStatusBubble {
    color: #fff;
    background-color: #D7DADE;
    border-radius: 50px;
    padding: 7px 14px;
    margin-left: -4px;
}

.projectTopStatusProgressValue {
    margin-top: -2px;
    margin-left: -4px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 15px;
}

.projectTopStatusAvatars {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
}

.projectTopStatusAvatarsNames {
    font-size: 14px;
    border-bottom: 1px dashed #A6A6A6;
}

.projectTopStatusProgressIconMask {
    width: 10px;
    height: 10px;
    background-color: #fff;
    position: absolute;
    left: 6px;
    top: 6px;
}

.visibilityOption {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 14px;
    margin-bottom: 10px;
    color: #5A5A5A;
    align-items: flex-start;
}

.visibilityCheckbox {
    width: 14px;
    height: 14px;
    border: 1px solid #5A5A5A;
    border-radius: 3px;
    position: relative;
    cursor: no-drop;
}

.visibilityCheckboxCheck {
    width: 10px;
    height: 10px;
    background-color: #5A5A5A;
    border-radius: 2px;
    position: absolute;
    left: 1px;
    top: 1px;
}

.nextSteps {
    display: flex;
    padding: 20px 40px;
    gap: 20px;
    justify-content: center;

    @media (max-width: $tablet) {
       display: block;
       padding: 10px 20px;
    }
}

.step {
    position: relative;
    max-width: 300px;
    font-size: 17px;
    color: #555555;
    text-align: center;
    background-color: transparent;
    padding: 20px;
    border-radius: 7px;

    @media (max-width: $tablet) {
        max-width: 100%;
        padding: 20px;
        margin-bottom: 30px;
        margin-top: 20px;
     }
}

.stepNumber {
    color: #9D9D9D;
    border: 4px solid #EDEDED;
    display: inline-block;
    font-size: 30px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.stepDescription {
    line-height: 1.2;
}

.stepAction {
    margin-top: 15px;
    color: #2960F6;
    cursor: pointer;
    transition: color 0.3s;
}

.stepAction:hover {
    color: #235af0;
    text-decoration: underline;
}

.stepDone {
    background-color: #00AB8E;
    color: #fff;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    right: -10px;
    top: -20px;
}

.lockedStep div {
    opacity: 0.3;
    cursor: default !important;
    user-select: none;
}

.lockedStep div:hover {
   text-decoration: none;
}

.imagePreviewWrapper {
	background-color: #fff;
	border-radius: 3px;
	display: inline-block;
	aspect-ratio: 2;
	width: 24%;
	margin-left: 0.5%;
	margin-right: 0.5%;
}

.imagePreview {
	position: relative;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center center;
	border-radius: 3px;
}

.imagePreviewTitle {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: transparent;
	margin-top: 6px;
	font-size: 12px;
	position: relative;
	z-index: 1000;
	opacity: 0.9;
}

.previewText {
	display: inline-block;
	padding: 3px;
	background-color: rgb(245, 245, 245);
	border-radius: 20px;
	padding: 7px 15px;
	margin-left: -2px;

	font-size: 14px;
	border-radius: 100px;
	padding: 8px 14px;
	user-select: none;
	border: 1px solid rgb(245, 245, 245);
	max-width: calc(100% - 30px);
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
    margin: 4px 0;
    width: fit-content;
}
.addInfoBtn {
    display: inline-block;
	padding: 3px;
	border-radius: 20px;
	padding: 7px 15px;
	margin-left: -2px;
	font-size: 14px;
	border-radius: 100px;
	padding: 8px 14px;
	user-select: none;
	border: 1px solid rgb(245, 245, 245);
	max-width: calc(100% - 30px);
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
    margin: 4px 0;
    width: fit-content;

    > a {
        text-decoration: none;
    }
    &:hover {
        box-shadow: 0 6px 16px rgba(0,0,0,0.12);
        > a {
            text-decoration: underline;
        }
    }
}
.imagePreviewSkeleton {
    margin: 0 4px;
    border-radius: 4px;
    width: 116px !important;
    height: 58px !important;

}
.chipSelector {
	display: flex;
	gap: 8px;
	width: 100%;
	flex-wrap: wrap;
	margin-top: 8px;
}
.chipPreview {
	font-size: 13px;
	border-radius: 100px;
	padding: 8px 14px;
	cursor: pointer;
	user-select: none;
	background-color: #017cf6;
	color: #fff;
	border: 1px solid #017cf6;
    width: fit-content;
}
.areBatteries {
    border-color: #28DB70;
    background-color: #28DB70;
}
.areSensors {
    border-color: #FFBA3B;
    background-color: #FFBA3B;
}
.colorBubble {
	height: 10px;
	width: 10px;
	display: inline-block;
	border-radius: 100%;
	margin-right: 10px;
}
.lists {
    display: flex;
    gap: 26px;
}
.list {
    // width: calc(50% - 26px);
}
.bottomBadge {
    display: flex;
    align-items: center;
    cursor: auto;
    margin: 16px 0px;
    justify-content: center;
}

.notPaidWall {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    cursor: no-drop;
}

.alexAvatar {
    width: 68px;
    height: 68px;
    background-size: cover;
    border-radius: 50%;
}

.alexSignature {
    width: 108px;
    height: 68px;
    background-size: auto 68px;
}
.winningProposalImg {
	width: 100px;
    min-width: 100px;
    max-width: 100px;
    height: 68px;
    object-fit: cover;
    border-radius: 8px;
    margin-right: 12px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px;
}

.feasibilityMessages {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 780px) {
	.feasibilityMessages {
		display: block;
	}
}

.demoProjectBanner {
    position: absolute;
    left: 0;
    margin-top: -55px;
    background-color: #fe6601;
    width: 100%;
    padding: 10px;
    text-align: center;
    font-weight: 500;
    color: #fff;
}
.listContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
}
.listItem {
    display: flex;
    align-items: center;
    margin: 8px;
}
.iconFilled {
    fill: $green;
    margin-right: 8px;
}
.iconPending {
    fill: $tertiary-gray;
    margin-right: 8px;
}
.linkText {
    color: $quaternary-gray;

    &:hover {
        color: $blue;
        cursor: pointer;
        text-decoration: underline;
    }
}
.linkTextAction {
    color: $quaternary-gray;
    margin-bottom: 1px;
    border-bottom: 1px solid $quaternary-gray;

    &:hover {
        color: $blue;
        cursor: pointer;
        border-bottom: 1px solid $blue;
    }
}
.linkTextDisabled {
    color: $quaternary-gray;
    margin-bottom: 1px;
    border-bottom: 1px solid transparent;
}
.newContestsContainer {
    @include flexRow;
    margin: 16px 0;
    gap: 16px;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-bottom: 16px;
}
.proposalsContainer {
	position: relative;
    display: flex;
    justify-content: center;
    gap: 2px;
    max-height: 200px;
    max-width: 326px;
}
.contestProposalImg {
    object-fit: cover;
    max-width: 100%;
    min-width: 100%;
    min-height: 200px;
    max-height: 200px;
    height: auto;
    vertical-align: middle;
}
.secondaryImgs {
    min-height: 68px;
    max-height: 68px;
    max-width: 100px;
    min-width: 100px;
}
.phaseTag {
    position: absolute;
    top: 12px;
    left: 16px;
	display: flex;
	align-items: center;
    justify-content: center;
	color: $white !important;
    border-radius: 4px !important;
    z-index: 10;
}
.winnerTag {
    position: absolute;
    top: 12px;
    right: 16px;
    z-index: 10;
    // box-shadow: 0 2px 10px rgba(242, 242, 242, 1);
}
.contributorsBlock {
    display: flex;
    flex-wrap: wrap;
    max-width: 126px;
    position: relative;

    :nth-child(1) {
        background-color: $tertiary-gray;
    }
    :nth-child(2) {
        position: absolute;
        left: 20px;
        background-color: $tertiary-gray;
    }
    :nth-child(3) {
        position: absolute;
        left: 40px;
        background-color: $tertiary-gray;
    }
    :nth-child(4) {
        position: absolute;
        left: 60px;
        background-color: $tertiary-gray;
    }
}
.contributor {
    background-color: $secondary-gray;
    border-radius: 50%;
    display: flex;
    @include centeredItems;
    width: 36px;
    height: 36px;
    color: $white;
    border: 2px solid $white;
}
.statusTag {
    text-transform: uppercase;
    color: $white !important;
}
.emptyProposal {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 326px;
    min-height: 268px;
}
.allProposalsSmall {
    display: flex;
    gap: 2px;
    padding: 0px 2px;
    overflow-x: scroll;
}