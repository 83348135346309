
@import '../../../scss/mixins';

.referralWrapper {
	display: flex;
	justify-content: center;
	width: 100%;
}

.referrals {
	text-align: center;
	width: 100%;
	max-width: 500px;
}
.title {
	display: block;
}

.descriptionWrap {
	display: flex;
	justify-content: center;
}

.description {
	font-size: 16px;
	margin-top: 10px;
	color: #6B6B6B;
	max-width: 310px;
	line-height: 1.2;
}

.illustration img {
	height: 200px;
}

.linkWrapper {
	margin-top: 30px;
	display: flex;
	justify-content: center;
}

.link {
	display: flex;
	width: 90%;
	max-width: 350px;
	justify-content: center;
	gap: 10px;
}

.link input {
	font-family: inherit;
	height: 50px;
	font-size: 17px;
	color: #5f5f5f;
	border: 1px solid #D5D5D5;
	border-radius: 10px;
	padding-left: 15px;
	width: 90%
}

.link .copy {
	background-color: #017cf6;
	width: 60px;
	border-radius: 10px;
	color: #fff;
	transition: background-color 0.3s;
	cursor: pointer;
	justify-content: center;
	display: flex;
	align-items: center;
	font-size: 22px;
}

.link .copy:hover {
	background-color: #0077ee;
}

.linkCopiedMessage {
	color: #328be4;
	margin-top: 6px;
	height: 20px;
}

.referredPeopleWrapper {
	display: flex;
	justify-content: center;
	margin-top: 40px;
}

.referredPeople {
	width: 90%;
	max-width: 320px;
	background-color: #F5F5F5;
	font-size: 16px;
	padding: 20px;
	padding-top: 25px;
	padding-bottom: 25px;
	border-radius: 10px;
	color: #4e4e4e;
	line-height: 1.2;
}

.socialLinks {
	margin-top: 10px;
	display: flex;
	justify-content: center;
	gap: 10px;
}

.socialLink {
	background-color: rgb(42, 42, 42);
	border-radius: 20px;
	padding: 7px 12px 7px 12px;
	color: #fff;
	align-items: center;
	display: flex;
	gap: 9px;
	cursor: pointer;
	transition: background-color 0.3s;
	font-size: 15px;
}

.socialLink:hover {
	background-color: rgb(59, 59, 59);
}
