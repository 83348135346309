@import '../../scss/_mixins.scss';

.box {
    width: 336px;
    cursor: pointer;
    border-radius: 8px;
    text-decoration: none;
    padding: 8px 16px;
    box-shadow: 0 2px 10px rgba(0,0,0,.2);

    &:hover {
        border: none;
		background-color: $primary-gray;
    }
    @media (max-width: $mobile) {
        margin: 8px 0;
        width: 82vw;
    }
}
.isMain {
	background-color: $tertiary-black;

	.bigIcon {
		color: $white !important;
	}
	& .contentBlock {
		> small, h4, h5 {
			color: $white !important;
		}
	}

	&:hover {
		background-color: $main-red;
    }
}
.contentBlock {
	@include flexColumn;
	justify-content: center;
}
.icon {
    color: $primary-black;
    @include flexRow;
    align-items: center;
	margin-right: 16px;
}
.bigIcon {
	width: 36px;
	height: 36px;
	color: $main-red;
}
.svgIcon {
    margin-right: 16px;
}
.goArrow {
    fill: $tertiary-gray !important;
    padding: 4px;
    width: 36px;
    height: 36px;
}
.chevronRight {
    margin: 8px 0;
    display: flex;
    justify-content: flex-end;
}
.blocksListContainer {
    display: flex;
    flex-wrap: wrap;
}
.blockBox {
    margin: 0 8px 8px 0;
	display: flex;
	justify-content: space-between;
}
.quickBlock {
    width: fit-content;
    height: 48px;
    display: flex;
    align-items: center;
	justify-content: space-between;
    border-radius: 4px;
    border: 1px solid $tertiary-gray;
	cursor: pointer;
	margin-right: 16px;
    padding: 16px;
    transition: 0.2s;
    position: relative;

	&:hover {
		background-color: $primary-gray;
		border: 1px solid $main-red;
		> h6 {
			color: $main-red;
		}
    }

	@media (max-width: $mobile-big) {
		margin: 8px;
	}

	> svg {
		color: $main-red;
        margin-right: 8px;
	}
}
.alternativeBlock {
    border: 1px solid $purple;
    transition: 0.2s;
    color: '#7145d6';

    > svg {
        color: $purple;
        margin-right: 8px;
    }

    &:hover {
		background-color: $purple;
        border: 1px solid $purple;
		> h6 {
			color: $white !important;
		}
        > svg {
            color: $white;
        }
    }
}
.alphaTag {
    position: absolute;
    top: 2px;
    right: -10px;
    border-radius: 4px !important;
    min-height: 2px !important;
    height: 12px;
    font-size: 8px !important;
}
.onShake {
    animation: shake 0.5s;
    animation-iteration-count: 5s;
}
@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
}