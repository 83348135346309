@import '../../../../scss/mixins';

.wrapper {
	display: flex;
	justify-content: center;
}

.content {
	background-color: transparent;
	width: 100%;
	max-width: 960px;
	margin-top: -12px;
}

.title {
	font-size: 32px;
	font-weight: 600;
	text-align: center;
	color: #313131;
	padding-top: 4px;
}

.descriptionWrapper {
	display: flex;
	justify-content: center;
	margin-top: 20px;
	width: 100%;
}

.description {
	font-size: 16px;
	text-align: center;
	width: 100%;
	max-width: 500px;
	opacity: 0.9;
	line-height: 1.2;
}

.description a {
	color: #357AEE;
	text-decoration-color: rgba(109, 157, 241, 0.765);
	transition: text-decoration-color 0.3s;
}

.description a:hover {
	text-decoration-color: #357AEE;
}

.blocks {
	border-top: 1px solid #e6e6e6c4;
	margin-top: 50px;
	margin-bottom: 50px;
	padding-top: 30px;
	display: flex;
	justify-content: space-between;
	gap: 60px;
}

.block {
	padding: 20px;
	text-align: center;
	width: 100%;
}

.blockTitle {
	font-size: 18px;
	font-weight: 600;
	opacity: 0.9;
}

.blockDescription {
	margin-top: 10px;
	line-height: 1.1;
	font-size: 16px;
	opacity: 0.7;
}

.bookSlotText {
	text-decoration: underline;
	text-decoration-color: #c5c5c5;
	cursor: pointer;
	transition: text-decoration-color 0.3s;
}

.bookSlotText:hover {
	text-decoration-color: #878787;
}

@media screen  and (max-width: 960px) {
	.content {
		margin-top: 0;
	}
}

@media screen  and (max-width: 760px) {
	.blocks {
		display: block;
	}
}

.showMessage {
	visibility: visible;
	opacity: 1;
	transition: visibility 0s linear 0s, opacity 300ms;
}

.hideMessage {
	visibility: hidden;
	opacity: 0;
	transition: visibility 0s linear 300ms, opacity 300ms;
}

.messageWrapper {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 20px;
}

.message {
	text-align: center;
	line-height: 1.1;
	background-color: #F8F8F8;
	border-radius: 6px;
	padding: 30px;
	max-width: 365px;
	font-size: 15px;
}

.alternativeMessage {
	background-color: transparent;
	border: 1px solid #e9e9e9;
}

.button {
	color: #fff;
	background-color: #357AEE;
	text-align: center;
	display: inline-block;
	padding: 10px 16px;
	border-radius: 6px;
	margin-top: 15px;
	cursor: pointer;
	transition: opacity 0.3s;
}

.button:hover {
	opacity: 0.9;
}

.loading {
	margin-top: 40px;
	text-align: center;
	color: #357AEE;
	height: 60px;
}

.lookingForMore {
	margin-top: 5px;
	color: rgb(141, 141, 141);
}

// Checkmark
.checkmark__circle{stroke-dasharray: 166;stroke-dashoffset: 166;stroke-width: 2;stroke-miterlimit: 10;stroke: #7ac142;fill: none;animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards}.checkmark{width: 42px;height: 42px;border-radius: 50%;display: block;stroke-width: 2;stroke: #fff;stroke-miterlimit: 10;margin: 0 auto;box-shadow: inset 0px 0px 0px #7ac142;animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both}.checkmark__check{transform-origin: 50% 50%;stroke-dasharray: 48;stroke-dashoffset: 48;animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards}@keyframes stroke{100%{stroke-dashoffset: 0}}@keyframes scale{0%, 100%{transform: none}50%{transform: scale3d(1.1, 1.1, 1)}}@keyframes fill{100%{box-shadow: inset 0px 0px 0px 30px #7ac142}}
.checkmark__circle_red{stroke-dasharray: 166;stroke-dashoffset: 166;stroke-width: 2;stroke-miterlimit: 10;stroke: #d94f36;fill: none;animation: stroke_red 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards}.checkmark_red{width: 42px;height: 42px;border-radius: 50%;display: block;stroke-width: 2;stroke: #fff;stroke-miterlimit: 10;margin: 0 auto;box-shadow: inset 0px 0px 0px #d94f36;animation: fill_red .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both}.checkmark__check_red{transform-origin: 50% 50%;stroke-dasharray: 48;stroke-dashoffset: 48;animation: stroke_red 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards}@keyframes stroke_red{100%{stroke-dashoffset: 0}}@keyframes scale_red{0%, 100%{transform: none}50%{transform: scale3d(1.1, 1.1, 1)}}@keyframes fill_red{100%{box-shadow: inset 0px 0px 0px 30px #d94f36}}

.votes {
	display: flex;
	justify-content: center;
	gap: 30px;
	margin-top: 20px;
	align-items: flex-start;
}

.vote {
	background: #FFFFFF;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
	border-radius: 6px;

	display: flex;
	width: 100%;
	max-width: 230px;
	padding: 14px;
	gap: 8px;
}

@media screen  and (max-width: 960px) {
	.votes {
		display: block;
	}
	.vote {
		max-width: 80%;
		margin-left: 10%;
		margin-top: 20px;
	}
}

.voteImage {
	width: 34px;
	height: 34px;
	border-radius: 50%;
	background-color: rgb(233, 233, 233);
	background-position: center center;
	background-size: cover;
}

.voteResult {
	font-weight: 400;
	font-size: 10px;
	line-height: 10px;

	color: #A9A9A9;
	text-transform: uppercase;
	margin-top: 2px;
}

.voteComment {
	line-height: 1.1;
	color: #646464;
	margin-top: 2px;
	font-size: 15px;
}
