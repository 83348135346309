@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;800&display=swap');
@import './scss/mixins';

body {
	font-family: $font-family !important;
	height: auto;
}
.bx--header~.bx--content {
	margin-top: 0 !important;
    padding-top: 5rem !important;
}
.bx--content {
	background: $white !important;
	@media (max-width: $mobile) {
		overflow-x: hidden;
	}
}
.bx--header__action:hover,
.bx--header__menu-trigger:hover {
    background-color: $white !important;
}
.bx--accordion__item {
	border-top: none !important;
	border-bottom: none !important;
}
.bx--accordion--start .bx--accordion__content {
	margin-left: 0 !important;
}
.bx--accordion__content {
	padding: 36px !important;

	@media (max-width: $mobile-big) {
        padding: 16px 0 !important;
    }
}
.toggleAddonContent {
	> .bx--accordion__content {
		padding: 0 !important;
	}
	.bx--accordion__title {
		color: $blue;
		margin: 0 8px;
	}
	.bx--tabs--scrollable .bx--tabs--scrollable__nav-item .bx--tabs--scrollable__nav-link {
		max-width: 96px;
	}
}
.bx--accordion__title {
	font-size: 1rem !important;
}
.bx--accordion--start .bx--accordion__heading {
	padding-right: 8px;
	align-items: center;
}

.toggleTitleProjectCard {
    .bx--accordion__title {
		color: $blue;
		@include flexRow;
		justify-content: flex-end;
	}
	.bx--accordion__arrow {
		margin: 4px 1rem 0 8px;
	}
}

.bx--header__menu-item {
	color: #9c9c9c !important;
}

.bx--header__menu-item:active,
.bx--header__menu-item:hover,
.bx--header__menu-item:focus
{
	color: $primary-black !important;
	background-color: $white !important;
	outline: none !important;
}
.bx--header__menu {
	border-radius: 4px !important;
	background-color: $white !important;
}
.bx--side-nav--ux {
	width: auto !important;
}

.bx--tab-content {
	padding: 0 !important;
}
.bx--modal-container {
	background-color: $white !important;
	border-radius: 4px;
}

.bx--file__drop-container {
	height: 8rem !important;
	padding: 2rem !important;
	align-items: center !important;
}

.bx--modal-footer button.bx--btn {
	padding: 16px;
    justify-content: center;
	font-size: 1rem;
	border-radius: 4px;
}

.bx--btn {
	font-size: 1rem !important;
}
.bx--modal {
	& .bx--modal-content {
		padding: 16px !important;
		margin-bottom: 0 !important;
	}
}

.bx--modal-content--overflow-indicator {
	background-image: none !important;
}

.bx--select-input {
	font-size: 1rem !important;
	border-bottom: 1px solid $main-red !important;
}

.bx--select--inline .bx--label {
    margin: 0 !important;
}
.bx--progress--vertical .bx--progress-label {
    max-width: 20rem !important;
}

.MuiGrid-item {
	@media (max-width: $mobile) {
    	width: -webkit-fill-available;
    	margin: 0 16px !important;
	}
}

.bx--text-input__field-wrapper {
	margin-bottom: 16px;
}

.bx--toggle-input__label .bx--toggle__switch {
    margin-top: 4px !important;
}

.bx--batch-summary__para {
	color: $white;
}
.bx--structured-list {
    margin-bottom: 2rem !important;
}
.bx--tooltip__trigger {
    cursor: help !important;
}
.bx--header__action.bx--btn--icon-only.bx--tooltip__trigger {
	cursor: pointer !important;
}

.MuiCircularProgress-colorSecondary {
    color: $primary-gray !important;

	.MuiCircularProgress-circle {
		stroke-width: 2.6 !important;
	}
}
.MuiCircularProgress-determinate {
	.MuiCircularProgress-circle {
		stroke-width: 2.2 !important;
		stroke-linecap: round !important;
		opacity: 0.8;
	}
}

.bx--modal-scroll-content > *:last-child {
    padding-bottom: 0 !important;
}

#checklist {
	.bx--checkbox:checked + .bx--checkbox-label::before {
		border-color: $green !important;
		background-color: $green !important;
	}
}

.bx--checkbox-label::before {
	border-radius: 4px !important;
}

.bx--content-switcher-btn.bx--content-switcher--selected {
	background-color: #161616 !important;
}
.bx--content-switcher-btn:last-child {
    border-right: 0.0625rem solid #161616 !important;
}
.bx--progress-line {
	width: 6.1rem !important;
}
.bx--progress--vertical {
	.bx--progress-line {
		width: 0 !important;
	}
}
.bx--progress--space-equal .bx--progress-line {
    width: 100% !important;
}
.bx--overflow-menu {
	height: 32px !important;
}