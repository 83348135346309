@import '../../../../../scss/_mixins.scss';

.additionalButtons {
	& svg {
		max-width: 20px;
		max-height: 20px;
	}
}
.tabContentContainer {
    margin: 36px 0;
}
.title {
    @include title;
}
.contentBlock {
    margin: 36px 0;
}
.contentTitle {
    margin-bottom: 16px;
}
.elementBox {
    @include elementBox;

    @media (max-width: $mobile) {
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: -webkit-fill-available;
    }
}
.winningProposal {
    background: $primary-gray;
    border: 1px solid $primary-gray;
}
.naming {
    @include naming;
}
.fileName {
    width: 150px;
}
.updateBox {
    @include updateBox;
}
.notification {
    width: 100%;

    @media (max-width: $mobile) {
        max-width: -webkit-fill-available !important;
    }
}
.btn {
    display: inline !important;
    border-radius: 4px !important;
    padding: 0 !important;
    text-align: center !important;
    width: -webkit-fill-available !important;

    @media (max-width: $mobile) {
        max-width: -webkit-fill-available !important;
    }
}
.fileBtn {
    width: 180px !important;
    min-height: 2.5rem !important;
}
.characteristics {
    @include characteristics;
    justify-content: space-between;

    @media (max-width: $mobile) {
        display: none;
    }
}
.inputCharBox {
    @include inputCharBox;
}
.actions {
    @include actions;
}
.fileAction {
    width: 100px;
}
.moreOptions {
    width: 260px;
    justify-content: flex-end;
}
.message {
    @include flexRow;
    align-items: center;
    svg {
        margin-right: 8px;
    }
}
.loadingBox {
    @include flexRow;
    @include centeredItems;

    p {
        vertical-align: middle;
        padding-left: .5rem;
        padding-right: .5rem;
    }
}
.uploadContainer {
    @include flexRow;
    justify-content: center;
}
.uploadContainerPreview {
    @include flexColumn;
    justify-content: center;
    align-items: center;
}
.previewImgFile {
    width: 500px;
    height: auto;
    margin: 16px;
    filter: drop-shadow(2px 4px 6px black);

    @media (max-width: $mobile) {
        width: -webkit-fill-available;
    }
}
.uploadBlock {
    @include flexColumn;

    a {
        margin: 16px 0;
        cursor: pointer;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}
.uploadBox {
    width: 600px;
    height: 350px;
    background: $primary-gray;
    border-radius: 8px;
    @include flexRow;
    @include centeredItems;
    cursor: pointer;

    &:hover {
        background: $secondary-gray;
    }

    @media (max-width: $mobile) {
        width: 80vw;
        height: 126px;
    }
}
.checkbox {
    @include flexRow;
    justify-content: center;
        
    div {
        @include flexRow;
        justify-content: center;
    }
    label {
        cursor: auto !important;

        &::before {
            background: $secondary-red !important;
            border-color: $secondary-red !important;
        }
    }
}
.checkboxInline {
    div {
        flex: none;
    }
}
.warningIcon {
    color: $yellow;
}
.loadingIcon {
    width: 32px !important;
    height: 32px !important;
}
.toastNotification {
    position: fixed;
    top: 50px;
    right: 50px;
}

.modalContent {
    padding: 0 36px;
    width: 100%;

    .contentElementsBox {
        @include flexColumn;
        margin: 16px 0;

        p {
            margin: 16px;
        }
    }
}

.statusTag {
    border-radius: 12px !important;
    text-transform: uppercase !important;
    letter-spacing: .5px !important;
    font-weight: 600 !important;
}

.updates {
    @include flexRow;

    svg {
        margin-right: 8px;
    }
}
.overviewMessageBox {
    @include overviewMessageBox;
    margin: 16px;
}
.phaseBubble {
    width: 36px;
    height: 36px;
    background: $secondary-gray;
    border-radius: 50%;
    @include flexRow;
    @include centeredItems;
    color: $white;
    font-weight: 600;
    margin: 4px;
}

.proposalContainer {
	position: relative;
	z-index: 1;
    margin: 8px;
    width: 300px;
    height: 168px;
    border-radius: 4px;
    border: 1px solid $secondary-gray;
    background: $primary-gray;
    transition: all 0.1s ease-in-out;
    object-fit: contain;

    &:hover {
        cursor: pointer;
        transform: scale(1.2);
        box-shadow: 0 6px 16px rgba(0,0,0,.2);
        background: $white;
        object-fit: contain;
        z-index: 2;

		.winnerContainer {
			opacity: 0;
		}
    }
}
.winnerContainer {
	position: absolute;
    top: 8px;
    right: 8px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: $white;
    display: flex;
	align-items: center;
    justify-content: center;
    transition: all 0.1s ease-in-out;
    box-shadow: 0 6px 16px rgba(0,0,0,.2);
}
.thumbnailImg {
	width: 100%;
    height: 100%;
    object-fit: cover;
}
.imgContainer {
    display: flex;
    justify-content: center;
}
.proposalImg {
    max-height: 300px;
    border-radius: 4px;
    margin: 16px 0;
    cursor: pointer;
}
.flexColumn {
    @include flexColumn;
}
.tooltip {
    margin: 16px;

    &:hover {
        cursor: pointer;
        color: $blue;
    }
}
.fileUploaderContainer {
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: space-between;
    height: 8rem;
    padding: 2rem;
    border: 1px dashed $secondary-red;
    border-radius: 4px;
    color: $secondary-red;

    &:hover {
        border: 2px solid $secondary-red;
        cursor: pointer;
        text-decoration: underline;
    }
}