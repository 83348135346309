@import './../../../../scss/mixins';

.selectProjectContainer {
	@include responsiveContainer;

	@media (max-width: $mobile-big) {
		padding: 0 !important;
	}
}
.title {
    @include title;
}
.projectsList {
	display: flex;
	flex-wrap: wrap;
	margin: 36px 0;
	gap: 16px;

	@media (max-width: $mobile-big) {
		justify-content: space-between;
	}
}
.gridItem {
    min-width: 326px;
    max-width: 326px;

    @media (max-width: $mobile) {
        min-width: -webkit-fill-available;
		width: 100%;
        margin: 0 0 16px 0;
    }
}
.projectCard {
	@include flexColumn;
	margin: 0 8px 8px 0;
	align-items: center;
	width: 300px;
	border-radius: 4px;
	transition: 0.3s;

	@media (max-width: $mobile-big) {
		width: -webkit-fill-available;
		margin: 16px 0;
	}
	&:hover {
		.projectName {
        	font-size: 20pt;
		}
    }
}
.projectImage {
	width: 100%;
	height: 150px;
	object-fit: cover;
	border-radius: 4px;

	@media (max-width: $mobile-big) {
		height: 150px;
    }
	&:hover {
        box-shadow: 0 6px 16px rgba(0,0,0,.2);
    }
}
.projectName {
	margin-top: 8px;
}
.quickBlock {
    width: fit-content;
    height: 48px;
    display: flex;
    align-items: center;
	justify-content: space-between;
    border-radius: 4px;
    border: 1px solid $tertiary-gray;
	cursor: pointer;
	margin-right: 16px;
    padding: 16px;

	&:hover {
		background-color: $primary-gray;
		border: 1px solid $main-red;
		> h6 {
			color: $main-red;
		}
    }

	@media (max-width: $mobile) {
		margin: 0;
	}

	> svg {
		color: $main-red;
	}
}
.bigHighlightedBlock {
	max-width: 1008px;
	min-height: 136px;
	max-height: 236px;
    background: $primary-gray;
    border-radius: 12px;
	display: flex;
	justify-content: space-between;
	color: $white;
	box-shadow: 0 6px 16px rgba(0,0,0,.2);
	margin-bottom: 16px;

	@media (max-width: $tablet) {
		flex-wrap: wrap;
		max-height: fit-content;
	}
}
.btn {
	@include btnPrimary;
	width: 200px !important;
}
.bottomBtnsContainer {
	display: flex;
	justify-content: flex-end;
	margin-top: 16px;

	@media (max-width: $mobile-big) {
		flex-wrap: wrap;
		justify-content: center;

		> button {
			margin: 8px;
		}
	}
}
.filterPhaseBox {
    width: 324px;
    margin: 36px 0;

    @media (max-width: $mobile) {
        width: -webkit-fill-available;
    }
}
.highlightImg {
	max-width: 400px;
	width: -webkit-fill-available;
    height: 100%;
	min-height: auto;
    object-fit: cover;
    border-radius: 12px 0 0 12px;

	@media (max-width: $tablet) {
		max-width: -webkit-fill-available;
		width: -webkit-fill-available;
		border-radius: 12px 12px 0 0;
		max-height: 236px;
	}
}
.flatTag {
    width: 116px;
    border-radius: 4px !important;
    min-height: 1rem !important;
    height: 20px;
    margin: 0 !important;
}
.leftHighlightedBlock {
	position: relative;
	@media (max-width: $tablet) {
        width: -webkit-fill-available;
    }
}
.rightHighlightedBlock {
	padding: 36px;
    width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
	max-height: 236px;

	@media (max-width: $mobile-big) {
        padding: 36px 16px;
		max-height: fit-content;
    }
}
.featuredProjectContent {
	display: flex;
	justify-content: space-between;

	@media (max-width: $mobile) {
		flex-direction: column;
		margin-bottom: 16px;
	}
}
.bottomMessage {
	margin-top: 16px;
	text-align: center;
	color: $tertiary-gray;
}
.littleText {
	color: $tertiary-black;
	font-size: 0.75rem;
	letter-spacing: 0.32px;
	margin-bottom: 16px;
}
.addonsBottomMessage {
	margin-top: 16px;
	color: $tertiary-black;
	width: 100%;
    text-align: center;
	color: $tertiary-gray;
}
.altImgBtn {
    position: absolute;
    top: 40%;
    right: 36%;
    left: 36%;
    background: $white;
    color: $secondary-black;
    border-radius: 8px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0 6px 16px rgba(0,0,0,.2);
	cursor: pointer;
}
.flexColumn {
	@include flexColumn;
}
.horizontalScrolling {
	display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    white-space: nowrap;
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    touch-action: manipulation;

    &:active {
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
    }
}