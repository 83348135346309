@import '../../scss/_mixins.scss';

.projectCard {
	height: 100%;
    width: 450px;
	
    @media (max-width: $mobile-big) {
        margin-bottom: 16px;
        width: -webkit-fill-available;
        height: auto;
    }
}
.projectCardImg {
	width: 100%;
    height: 300px;
    border-radius: 4px;
    object-fit: cover;
}
.projectCardImgSmall {
    width: 300px;
   	height: 200px;
    border-radius: 4px;
    object-fit: cover;
}