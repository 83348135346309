@import './../../../../scss/mixins';

.profileContainer {
    width: 100%;
    @include responsiveContainer;
}
.title {
    @include title;
}
.breadcrumbBox {
    @include breadcrumbBox;
    background: none;
    padding: 0;
    margin-bottom: 8px;
}
.personalInfoContent {
    display: flex;
    justify-content: space-evenly;
    margin: 36px 0;

    @media (max-width: $mobile-big) {
        @include flexColumn;
        align-items: center;
    }
}
.divider {
	background-color: $secondary-gray;
    margin: 26px 0px;
    height: 1px;
}
.avatarContainer {
    @include flexColumn;
    @include centeredItems;

	border: 1px solid $secondary-gray;
    border-radius: 4px;
    padding: 8px 16px;
	max-height: 356px;
	width: 248px;

	@media (max-width: $mobile-big) {
		width: -webkit-fill-available;
		border: none;
    }
}
.profileAvatar {
    @include avatar;
}
.avatarImg {
    @include avatarImg;
}
.infoElements {
    @include infoElement;
    align-items: center;
}
.btn {
    @include btnWithIcon;
}
.textInput {
    background-color: $primary-gray !important;
    border-bottom: none !important;
	margin-left: 8px;
	border-radius: 2px;
}
.informationBox {
	width: 400px;

	@media (max-width: $mobile-big) {
		width: -webkit-fill-available;
		margin: 36px 0;
	}
}
.section {
    margin: 36px 0;
}
.sectionContent {
    display: flex;
    align-items: center;
    margin: 16px 0;
}
.editBtn {
    display: flex;
    justify-content: flex-start;

    @media (max-width: $mobile-big) {
		justify-content: flex-end;
	}
}