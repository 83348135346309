@import '../../scss/mixins';

.extraBlock {
	margin: 0 16px 16px 0;
    min-width: 326px;
    max-width: 326px;
	min-height: 132px;
	background-color: $white;
	border: 1px solid $secondary-gray;
	border-radius: 8px;
	padding: 16px;
	cursor: pointer;
	box-shadow: 3px 3px 5px 0px rgba(204, 204, 204, 0.1);
    transition: box-shadow .3s,transform .3s,-webkit-box-shadow .3s,-webkit-transform .3s;
    transform: translateY(-4px);
}

.extraBlock:hover {
	box-shadow: 3px 3px 5px 0px rgba(204, 204, 204, 0.5);
}

.icon {
	> svg {
		width: 30px;
		height: 30px;
	}
}
.title {
	margin: 8px 0;
}
.altText {
	color: $tertiary-black;
	font-size: 0.75rem;
}
.hoverBlock {
	min-height: 168px;
}
.bottomAction {
	display: flex;
	justify-content: flex-end;
	color: $blue;
	margin-top: 16px;
}
.actionGroup {
	display: flex;
	align-items: center;
}