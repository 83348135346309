@import '../../scss/_mixins.scss';

.caseStudy {
	border: 1px solid $secondary-gray;
	border-radius: 16px;
	width: 300px;
	padding: 16px;
	@include flexColumn;
}
.topContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 8px;
}
.bottomContainer {
	@include flexColumn;
	justify-content: space-between;
	height: 120px;
}
.caseImg {
	width: -webkit-fill-available;
	height: 180px;
	border-radius: 4px;
	object-fit: cover;
	margin: 16px 0;
}
.littleText {
	color: $tertiary-black;
	font-size: 0.75rem;
	letter-spacing: 0.32px;
}
.tag {
	border-radius: 4px!important;
    height: 20px;
    margin: 0!important;
    min-height: 1rem!important;
}
.actionBox {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.link {
    color: $blue;
	cursor: pointer;
}