@import './../../../../scss/_mixins.scss';

.projectViewPage {
    @include responsiveContainer;
}
.loadingContainer {
    width: -webkit-fill-available;
	margin: 0px auto;
}
.breadcrumbBox {
    @include breadcrumbBox;
}
.imgContainer {
    margin: 16px 0;
    width: -webkit-fill-available;
    position: relative;
    height: 350px;
}
.cardImage {
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-radius: 4px;
    // filter: blur(.5px);
	background: #eee;

    @media (max-width: $mobile) {
        height: auto;
    }
}
.hide {
    display: none;
}
.hideBtn {
    color: $white;
    border-radius: 4px;
    position: absolute;
    right: 16px;
    bottom: 16px;
    width: 100px;
    @include flexRow;
    @include centeredItems;
    padding: 8px;
    opacity: .5;
    cursor: pointer;

    &:hover {
        background: $tertiary-black;
        color: $white;
        opacity: 1;
    }
}
.imgSkeleton {
    width: 100% !important;
    height: 350px !important;
    margin: 16px 0;
}
.containerSkeleton {
    margin: 36px 0;
}
.header {
    margin-bottom: 20px;
}
.stateContainer {
    padding: 16px 0;

    @media (max-width: $mobile-big) {
        display: none;
    }
}
.space {
    padding: 36px 0;
}
.projectInfoContainer {
    @include flexRow;
    margin: 36px 0px;
    width: -webkit-fill-available;

    @media (max-width: $tablet) {
        flex-direction: column;
        margin: 0;
    }
}
.infoContainer {
    margin-right: 36px;

    @media (max-width: $tablet) {
        margin: 36px 0;
    }
    @media (max-width: $mobile) {
        margin: 8px 0;
    }
}
.title {
    margin-bottom: 8px;
}
.detailsContainer {
    margin: 26px 0;
}
.projectInfo {
    flex: 1;
}
.infoElements {
    @include infoElement;

    h6 {
        font-size: .95rem;
    }

    @media (max-width: $tablet) {
        width: 100%;
    }
}
.extraInfoBlock {
    @include flexRow;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
}
.description {
    margin: 36px 0;

    h5 {
        margin-bottom: 8px;
    }
    p:nth-of-type(2n+1) {
        margin-bottom: 16px;
    }
}
.tags {
    @include flexRow;
    flex-wrap: wrap;
}
.sideInfo {
    position: sticky;
    display: inline-block;
    position: -webkit-sticky;
    -ms-flex-item-align: start;
    align-self: flex-start;
    top: 86px;

    @media (max-width: $tablet) {
        position: initial;
        align-self: initial;
    }
}
.date {
    @include flexRow;
    flex-wrap: nowrap;
    align-items: flex-end;
    margin-bottom: 20px;
}
.projectStatus {
    border: 1px solid $secondary-gray;
    border-radius: 4px;
    padding: 8px 16px;
    width: 326px;

    @media (max-width: $tablet) {
        display: contents;
    }
}
.projectStatusTitle {
    padding-bottom: 8px;
    border-bottom: 1px solid $tertiary-gray;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.projectStatusBox {
    padding: 16px 0;
}
.projectStatusQuickAccess {
    @include projectStatusQuickAccess;
}
.projectDetails {
    @include flexRow;
    justify-content: space-between;
    align-items: center;
    margin: 6px 0;

    a {
        text-decoration: none;
    }
    a:hover {
        text-decoration: underline;
    }
}
.actions {
    @include flexColumn;
    flex-wrap: nowrap;
}
.contestLink {
    @include flexColumn;

    a {
        margin: 16px 0;
        text-align: center;
        color: $main-red;
    }
}
.btn {
    display: inline !important;
    border-radius: 4px !important;
    padding: 0 !important;
    text-align: center !important;
    width: -webkit-fill-available !important;

    @media (max-width: $tablet) {
        max-width: -webkit-fill-available !important;
    }
}
.loadingBtn {
    background-color: $primary-gray !important;
}
.inlineLoading {
    justify-content: center;
}
.notification {
    min-width: 100% !important;
    width: 100% !important;
    margin-bottom: 0 !important;
}
.tabContainer {
    width: 100%;
}
.tabs {
    width: 100%;
    overflow: hidden;
    
    ul {
        width: 100%;
        
        li, button {
            text-align: center !important;
            outline: none !important;
            text-transform: uppercase !important;
            letter-spacing: .5px !important;
        }
    }
}
.contributorsContainer {
    margin: 36px 0;
}
.contributorsList {
    @include flexRow;
    flex-wrap: wrap;
}
.contributorsBubble {
    width: 36px;
    height: 36px;
    background: $secondary-gray;
    border-radius: 50%;
    @include flexRow;
    @include centeredItems;
    color: $white;
    font-weight: 600;
    margin: 4px;
}
.returnActionModal {
    @include flexRow;
    align-items: center;
    margin-bottom: 16px;

    span {
        color: $secondary-red;
    }
}
.linkModal {
    @include link;
}
.listModal {
    padding: 0 16px;
    margin: 16px;

    ul {
        list-style-type: disc;
        li  {
            margin: 4px 0;
        }
    }
}
.modalContent {
    padding: 0 36px;
}
.contestContent {
    margin: 16px 0;
    p {
        padding: 4px 16px;
    }
}
.contestImg {
    width: 100%;
    height: 400px;
    object-fit: contain;
    margin: 16px;
}
.lockContainer {
    @include flexColumn;
    align-items: center;
}
.lockContent {
    filter: blur(8px);
    pointer-events: none;
    width: -webkit-fill-available;
}
.lockBox {
    flex-grow: 1;
    margin-top: -268px;
    @include flexColumn;
    align-items: center;
}
.lockInfo {
    margin: 16px;
}
.tile {
    border-radius: 4px;
    margin: 16px 0px;
    width: 480px !important;
}
.listItem {
    margin: 16px;
    list-style-type: disc;

    li  {
        margin: 6px 16px;
    }
}
.specialAttentionBlock {
    @include flexRow;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: $tablet) {
        @include flexColumn;
    }
}
.link {
    cursor: pointer;
    color: $blue;
}
.circleLocked {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    padding: 8px;
    opacity: 0.5;

    &:hover {
        background: $tertiary-black;
        opacity: 1;

        > svg {
            fill: $white !important;
        }
    }
}