@import '../../../../scss/mixins';

.contestViewPage {
    @include responsiveContainer;
	width: 100%;
}
.loadingContainer {
    width: -webkit-fill-available;
	margin: 0px auto;
}
.secondaryTitle {
	font-size: 22px;
}
.titleSpace {
    margin-bottom: 4px;
}
.labelTitle {
    color: #717171;
}
.flexColumn {
    @include flexColumn;
}
.betweenBlock {
	display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.horizontalDivider {
	border-top: 1px solid rgb(221, 221, 221);
	border-radius: 2px;
	margin: 36px 0;
}
.spaceBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.contestContentBlock {
    margin: 36px 0;
}
.contentBlock {
    margin: 16px 0;
}
.contestStepsBlock {
    @include flexRow;
    gap: 36px;

    @media (max-width: $tablet) {
        @include flexColumn;
    }
}
.stepsContainer {
    margin: 0px 36px 0 16px;
    min-width: 150px;
    width: 100px;

    @media (max-width: $tablet) {
        margin: 0px 16px 36px 0;
        width: -webkit-fill-available;
    }
}
.stepTitle {
    margin-bottom: 36px !important;
	// margin: -2px 0 6px 0;

    @media (max-width: $tablet) {
        display: none;
    }
}
.imageContainer {
    display: flex;
    justify-content: center;
}
.proposalImg {
    @include proposalImg;
    border-radius: 12px 12px 0 0;
}
.imageCardSkeleton {
    width: 348px;
    height: 200px;
    object-fit: cover;
    border-radius: 12px 12px 0 0;
    background: $primary-gray;
    filter: brightness(0.95);

    @media (max-width: $mobile) {
        width: -webkit-fill-available;
    }
}
.overviewMessageBox {
    @include overviewMessageBox;
}
.proposalsContainer {
    @include flexRow;
    flex-wrap: wrap;
}
.proposalCard {
    position: relative;
    background: $white;
    border: 1px solid $secondary-gray;
    border-radius: 12px;
    margin: 16px 16px 16px 0;

    &:hover {
        box-shadow: 0 6px 16px rgba(0,0,0,.2);
        transform: translateY(-2px);
		cursor: pointer;
    }
    @media (max-width: $mobile-big) {
        width: -webkit-fill-available;
        margin: 0 0 16px 0;
    }
}
.skeletonProposal {
    width: 350px;

    @media (max-width: $mobile) {
        width: -webkit-fill-available;
    }
}
.proposalHeader {
    @include flexRow;
    justify-content: space-between;
}
.proposalCardContent {
    padding: 16px;
}
.feedbackToggle {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}
.contestContent {
    margin: 16px 0;
    p {
        padding: 4px 16px;
    }
    .listModal {
        padding: 0 16px;
        margin: 16px;
        list-style-type: disc;

        li  {
            margin: 4px 0;
        }
    }
}
.btn {
    @include btnPrimary;
}
.btnAlternative {
    @include btnPrimary;
    background-color: $gold !important;
}
.winningProposalSpace {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 1;
    background-color: $white;
    border-radius: 50%;
}
.projectStatus {
    border: 1px solid $secondary-gray;
    border-radius: 4px;
    padding: 8px 16px;
    width: 300px;
    height: max-content;
    margin: 16px 0;

    @media (max-width: $mobile-big) {
        display: contents;
    }
}
.phaseFeedbackRating {
    position: fixed;
    background: $white;
    bottom: 6rem;
    z-index: 10;
    width: 350px;
    right: 6rem;
    margin-bottom: 0;
}
.projectTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $tertiary-gray;
    padding-bottom: 8px;
}
.projectStatusBox {
    padding: 16px 0;
    display: flex;

    @media (max-width: $mobile-big) {
        flex-wrap: wrap;
    }
}
.contestStatusBox {
    @include flexRow;
    width: -webkit-fill-available;
    gap: 36px;

    @media (max-width: $tablet) {
        flex-direction: column;
        margin: 0;
        gap: 0;
    }
}
.projectDetails {
    @include flexRow;
    justify-content: space-between;
    align-items: center;
    margin: 6px 0;

    a {
        text-decoration: none;
    }
    a:hover {
        text-decoration: underline;
    }
}
.marginBottom {
    margin-bottom: 16px;
}
.notification {
    @media (max-width: $mobile-big) {
        max-width: -webkit-fill-available !important;
    }
}
.updatesContentContainer {
    height: 100%;
}
.summaryBlock {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media (max-width: $mobile) {
        flex-direction: column;
        align-items: center;
        margin: 8px;
    }
}
.elementsSummaryBox {
    display: flex;
    align-items: center;

    svg {
        margin-right: 6px;
    }
}
.experienceBlock {
    @include flexRow;
    margin: 8px 0;

    @media (max-width: $mobile-big) {
        width: -webkit-fill-available;

        > div {
            margin: 8px;
        }
    }
}
.statisticBox {
    @include flexColumn;
    justify-content: center;
    background: #f6f6f6;
    border-radius: 8px;
    width: 136px;
    height: 80px;
    padding: 16px;
    margin-right: 16px;
}
.listItem {
    margin: 16px;
    list-style-type: disc;

    li  {
        margin: 6px 16px;
    }
}
.feedbackBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 6rem;
    right: 20px;
    position: fixed;
    background: $primary-gray;
    background: $gold;
    color: $white;
    width: 58px;
    height: 58px;
    padding: 8px;
    cursor: pointer;
    border-radius: 50%;
    z-index: 10;

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px, rgba(0, 0, 0, 0.3) 0px 4px 28px;
    }
}
.shadow {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px, rgba(0, 0, 0, 0.3) 0px 4px 28px;
}
.clientFeedbackRating {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}
.clientFeedbackComment {
    width: 100%;
    background-color: $primary-gray;
    display: flex;
    height: 100%;
    align-items: center;
    padding: 1rem;
    border-radius: 4px;
    margin-top: 8px;
}
.imagesContainer {
    display: flex;
    flex-wrap: wrap;
}
.thumbnailContainer {
    position: relative;
	z-index: 1;
    margin: 8px;
    width: 150px;
    height: 100px;
    border-radius: 8px;
    border: 1px solid $secondary-gray;
    background: $primary-gray;
    transition: all 0.1s ease-in-out;
    object-fit: contain;
}
.contestThumbnail {
    width: 100%;
    max-width: 148px;
    height: 98px;
    object-fit: cover;
    border-radius: 8px;
	background: $primary-gray;
    margin-right: 16px;

    @media (max-width: $mobile) {
        height: auto;
    }
}
.contextContent {
    margin: 8px;
}
.colorBlock {
    border-radius: 12px;
    width: max-content;
    max-width: fit-content;
    background-color: $primary-gray;
    padding: 1rem;
}
.listSpecialAttention {
    padding: 16px 36px;

    > ul {
        list-style: circle;
    }
}
.contestText {
    color: $secondary-black;
    margin: 0 8px;
}
.flexCenter {
    display: flex;
    align-items: center;
}
.btnBox {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;

    @media (max-width: $tablet) {
        @include flexColumn;
    }
}
.iconText {
    margin-right: 6px !important;
    fill: $secondary-red !important;
}
.aBtn {
    padding: 8px !important;
}
.alignItems {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.alignContainer {
    display: flex;
    align-items: center;
}
.contestProposalsContainer {
    display: flex;
    justify-content: center;
    width: -webkit-fill-available;
    border-radius: 8px;
    height: 200px;
    background: #D9D9D912;
    border: 1px dashed #dcdcdc;
}
.lockContainer {
	@include flexColumn;
    @include centeredItems;
	align-items: center;
}
.lockedIcon {
    margin-bottom: 10px;
    font-size: 30px;
    color: #808080;
}
.projectThumbnail {
    width: 100%;
    max-width: 100px;
    min-width: 100px;
    height: 68px !important;
    object-fit: cover;
    border-radius: 8px;
	background: $primary-gray;
    margin-right: 16px;

    @media (max-width: $mobile) {
        height: auto;
        display: none;
    }
}
.contestStatus {
    border: 1px solid $secondary-gray;
    border-radius: 4px;
    padding: 8px 16px;
    max-width: 326px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media (max-width: $tablet) {
        margin-top: 16px;
    }
}
.containerSkeleton {
    margin: 36px 0;
}
.proposalSkeleton {
    width: 300px !important;
    height: 200px !important;
    margin: 8px;
}
.linkModal {
    @include link;
}
.linkPosition {
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
}
.infoContainer {
    margin-right: 36px;

    @media (max-width: $tablet) {
        margin: 36px 0;
    }
    @media (max-width: $mobile) {
        margin: 8px 0;
    }
}

/* Unlock */
.unlockContentWrapper {
    display: flex;
    justify-content: center;
}

.unlockContent {
    width: 80%;
    font-size: 16px;
    @media (max-width: $mobile-big) {
        width: 85%;
	}
}

.unlockSectionDescription {
    text-align: center;
    line-height: 1.2;
}

.vision {
    display: flex;
    justify-content: center;
    gap: 40px;
    margin-top: 30px;
    width: 80%;
    margin-bottom: 80px;

    flex-direction: flex-wrap;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: $tablet) {
        justify-content: center;
	}
}

.visionItem {
    background-color: transparent;
    width: calc(33% - 40px);

    @media (max-width: $tablet) {
        width: calc(50% - 30px);
	}

    @media (max-width: $mobile-big) {
        width: 100%;
	}
}

.visionTitle {
    font-size: 20px;
    font-weight: 600;
    margin-top: 12px;
    text-align: center;
}

.visionImage {
    width: 75px;
    height: 75px;
    background-size: 75px 75px;
    background-repeat: no-repeat;
}

.unlockCenter {
    display: flex;
    justify-content: center;
}

.visionDescription {
    text-align: center;
    margin-top: 15px;
    line-height: 1.2;
}

.features {
    display: flex;
    flex-direction: flex-wrap;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 30px;
}

.feature {
    background-color: #F6F4F2;
    border-radius: 9px;
    padding: 20px;
    width: calc( 33% - 30px);
    position: relative;

    @media (max-width: $tablet) {
        width: calc(50% - 30px);
	}

    @media (max-width: $mobile-big) {
        width: 100%;
	}
}

.featureTitle {
    font-size: 17px;
    color: #CA2C3B;
    font-weight: 600;
    text-align: center;
}

.featureDescription {
    text-align: center;
    font-size: 15px;
    margin-top: 10px;
    line-height: 1.2;
}

.featureActive {
    text-transform: uppercase;
    font-size: 11px;
    color: #434343;

    background: #DEF8F0;
    border-radius: 6px;
    display: inline-block;
    position: absolute;
    right: -5px;
    top: -5px;
    padding: 5px 10px;
    font-weight: 600;
}

.unlockLink {
    margin-top: 25px;
    text-decoration: underline;
    cursor: pointer;
}

.pricing {
    margin-top: 80px;
    width: 90%;
    max-width: 500px;
    background-color: transparent;
}

.fullSection {
    border-radius: 9px;
    background-color: #F6F4F2;
    width: 100%;
    max-width: 360px;
    text-align: center;
    line-height: 1.2;
}

.fullButton {
    color: #fff;
    font-weight: 600;
    background: linear-gradient(270deg, #4E27F0 0%, #336EDE 85.77%);
    border-radius: 9px;
    padding: 20px 15px;
    cursor: pointer;
    transition: opacity 0.3s;
}

.fullButton:hover {
    opacity: 0.95;
}

.fullButtonDescription {
    padding: 12px 20px;
    font-size: 15px;
    color: #505050;
    position: relative;
    line-height: 1.2;
}

.specialOffer {
    background: #FADE4A;
    border-radius: 5px;
    transform: rotate(-5.46deg);
    text-align: center;
    text-transform: uppercase;

    color: #363636;
    display: inline-block;
    font-weight: 700;
    font-size: 12px;
    padding: 5px 10px;

    position: absolute;
    width: 110px;
    bottom: -10px;
}

.pricingDivider {
    margin-top: 40px;
    margin-bottom: 40px;
    position: relative;
}

.pricingDividerLine {
    width: 100%;
    height: 1px;
    border-radius: 10px;
    background-color: #E6E6E6;
}

.pricingDividerText {
    position: absolute;
    width: 30px;
    height: 20px;
    background-color: #fff;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: -9px;
    text-align: center;
    font-size: 15px;
    color: #8B8B8B;
}

.secondButtonDescription {
    text-align: center;
    font-size: 15px;
    color: #8B8B8B;
    margin-bottom: 14px;
}

.secondSection {
    border-radius: 9px;
    background-color: #F6F4F2;
    width: 100%;
    max-width: 400px;
    text-align: center;
    line-height: 1.2;
}

.secondButton {
    color: #473CEB;
    font-weight: 600;
    background: #fff;
    border-radius: 9px;
    padding: 16px 9px;
    cursor: pointer;
    transition: opacity 0.3s;
    border: 3px solid #473CEB;
}

.secondButton:hover {
    opacity: 0.95;
}

.unlockFooter {
    color: #8D8D8D;
    line-height: 1.2;
    font-size: 13px;
    width: 100%;
    max-width: 800px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
}
