@import '../../scss/_mixins.scss';

.modalContent {
    padding: 0 16px;

    .contestContent {
        margin: 16px 0;

        a {
            cursor: pointer;
        }
        p {
            padding: 4px 16px;
        }
        .listModal {
            padding: 0 16px;
            margin: 16px;
            list-style-type: disc;
        
            li  {
                margin: 4px 0;
            }
        }
    }
}
.ratingContent {
    @include flexRow;
    justify-content: space-between;
}
.ratingHearts {
    @include flexRow;
    justify-content: center;
    margin: 16px;

    span > label > span > svg {
        width: 2em;
        height: 2em;
    }
}

.notification {
    width: 100% !important;
}

.contestContent {
    margin: 16px 0;
    p {
        padding: 4px 16px;
    }
}
.contestImgContainer {
	position: relative;
	cursor: pointer;
    margin: 16px;
    border-radius: 4px;

	&:hover {
		.contestImgOverlay {
			opacity: .8;
		}
	}
}
.contestImg {
    width: 100%;
}
.contestImgOverlay {
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background: $primary-black;
	color: $white;
	opacity: 0;
	display: flex;
    align-items: center;

	div {
		display: flex;
		align-items: center;
		text-align: center;
		justify-content: center;
		width: 100%;
		text-align: center;
		font-size: 20px;

		svg {
			max-width: 20px;
			margin-right: 5px;
		}
	}
}
.btnBox {
    display: flex;
    justify-content: flex-end;
}
.btn {
    @include btnPrimary;
    width: 200px !important;
    margin: 8px !important;
}
.large {
    width: -webkit-fill-available !important;
}
.inlineLoading {
    justify-content: center !important;
    font-size: 1rem !important;
    color: $white !important;
}
.imgContainer {
    display: flex;
    justify-content: center;
}
.proposalImg {
    max-height: 300px;
    border-radius: 4px;
    margin: 16px 0;
    cursor: pointer;
}
.link {
    cursor: pointer;
    color: $blue;
}
.legend {
    font-size: 0.75rem;
    letter-spacing: 0.32px;
    display: inline-block;
    margin-bottom: 0.5rem;
    color: #525252;
    font-weight: 400;
    line-height: 1rem;
    vertical-align: baseline;
}
.infoNotification {
    @include flexRow;
    align-items: center;
    margin: 8px;

    svg {
        margin-right: 8px;
    }
}
.libraryContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 16px;
    gap: 20px 10px;
}
.libraryImage {
    min-width: 200px;
    max-width: 200px;
    height: 150px;
    object-fit: cover;
    margin: 8px;
    border-radius: 8px;
}
.projectLibraryImageWrapper {
    position: relative;
    width: 30%;
    height: 150px;
}
.projectLibraryImage {
    position: relative;
    background-color: red;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-size: cover;
    background-position: center center;
    background-color: rgb(249, 249, 249);
    border-radius: 8px;
    border: 1px solid rgb(245, 245, 245);
}
.projectLibrarySelectedImage {
    width: 80%;
    height: 250px;
    margin-top: 15px;
    margin-bottom: 20px;
    margin-left: 10%;
    cursor: inherit;
}
.imageCredits {
    position: absolute;
    bottom: 0;
    background: $tertiary-black;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    border-radius: 0 0 8px 8px;
    padding-top: 5px;
    padding-bottom: 5px;
    opacity: 0.8;
    transition: opacity 0.3s;
}
.imageCredits:hover {
    opacity: 1;
}
.credits {
    color: $white;
    cursor: pointer;
}
.credits a {
    color: inherit;
    text-decoration: inherit;
}

.returnActionModal {
    @include flexRow;
    align-items: center;
    margin-bottom: 16px;
    cursor: pointer;
    transition: opacity 0.3;

    span {
        color: $secondary-red;
        opacity: 1;
    }

    &:hover {
        span {
            opacity: 0.9;
        }
	}
}
.unsplashAttribution {
    text-align: right;
    opacity: 0.7;
    font-size: 13px;
    margin-top: 10px;
}
.unsplashAttribution a {
    color: inherit;
}
.progressContainer {
    margin: 16px 0 36px 0;

    button > div > p {
        padding-right: 0;
    }
}
.secondaryTitle {
    margin-bottom: 16px;
}
.btnContainer {
    display: flex;
    justify-content: flex-end;
    margin: 16px 0;
}