@import '../../scss/_mixins.scss';

.modalContent {
    padding: 0 16px;

    .contestContent {
        margin: 16px 0;

        a {
            cursor: pointer;
        }
        p {
            padding: 4px 16px;
        }
        .listModal {
            padding: 0 16px;
            margin: 16px;
            list-style-type: disc;
        
            li  {
                margin: 4px 0;
            }
        }
    }
}
.ratingContent {
    @include flexRow;
    justify-content: space-between;
}
.ratingHearts {
    @include flexRow;
    justify-content: center;
    margin: 16px;

    span > label > span > svg {
        width: 2em;
        height: 2em;
    }
}
.notification {
    width: 100% !important;
}
.contestContent {
    margin: 16px 0;
    p {
        padding: 4px 16px;
    }
}
.contestImgContainer {
	position: relative;
	cursor: pointer;
    margin: 16px;
    border-radius: 4px;

	&:hover {
		.contestImgOverlay {
			opacity: .8;
		}
	}
}
.contestImg {
    width: 100%;
}
.contestImgOverlay {
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background: #000;
	color: $white;
	opacity: 0;
	display: flex;
    align-items: center;

	div {
		display: flex;
		align-items: center;
		text-align: center;
		justify-content: center;
		width: 100%;
		text-align: center;
		font-size: 20px;

		svg {
			max-width: 20px;
			margin-right: 5px;
		}
	}
}
.btnBox {
    display: flex;
    justify-content: flex-end;
}
.btn {
    @include btnPrimary;
    width: 200px !important;
    margin: 8px !important;
}
.inlineLoading {
    justify-content: center !important;
    font-size: 1rem !important;
    color: $white !important;
}
.helpBox {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    a {
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}
.imgContainer {
    display: flex;
    justify-content: center;
}
.proposalImg {
    max-height: 300px;
    border-radius: 4px;
    margin: 16px 0;
    cursor: pointer;
}