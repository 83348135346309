@import '../../../scss/mixins';

.marketplaceContainer {
    @include responsiveContainer;
	width: 100%;
}
.addonList {
	display: flex;
    flex-wrap: wrap;
	margin: 36px 0;
}
.addonsBottomMessage {
	margin-top: 16px;
	color: $tertiary-black;
	width: 100%;
    text-align: center;
	color: $tertiary-gray;
}
.servicesList {
	display: flex;
	flex-wrap: wrap;
	// justify-content: center;
	gap: 36px;
	margin: 36px 0;
}
.horizontalDivider {
	border-top: 1px solid $quinternary-gray;
	border-radius: 2px;
	margin: 36px 0;
}
.tag {
    color: #313131;
    background-color: #F3F3F3;
    border-radius: 4px;
    padding: 6px 9px
}
.tooltipText > button {
	border-bottom: none !important;
	text-align: start !important;
}
.secondaryTitle {
	font-size: 22px;
}
.titleSpace {
    margin-bottom: 8px;
}
.breadcrumbBox {
    @include breadcrumbBox;
    background: none;
    padding: 0;
    margin-bottom: 8px;
}
.serviceBlock {
	display: flex;
    min-width: 450px;
    max-width: 450px;
	cursor: pointer;

	@media (max-width: $tablet) {
		min-width: 350px;
		max-width: 350px;
    }
}
.spaceBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.gifImage {
	position: absolute;
	right: 4rem;
    top: -4rem;
    width: 400px;
    height: auto;

	@media (max-width: $tablet) {
        display: none;
    }
}
.filterList {
	display: flex;
	gap: 8px;
	flex-wrap: wrap;
}
.serviceLogo {
	width: 84px;
    height: 84px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 16px;
    border: 1px solid #DDD;
}
.packageDetailBox {
	width: 450px;
	padding: 16px;
	border-top: 4px solid #0969da;
	background: $white;
    border-radius: 4px;
	box-shadow: 0 8px 24px rgba(140, 149, 159, 0.20);

	@media (max-width: $tablet) {
		width: -webkit-fill-available;
	}
}
.flexCenter {
	display: flex;
	align-items: center;
}
.icon {
	margin-right: 8px;
}
.label {
	line-height: 1.25;
	font-size: 12px;
	color: #57606a;
}
.optionSelector {
	width: 400px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    // border: 1px solid #ddd;
    border-radius: 8px;
	cursor: pointer;

	@media (max-width: $tablet) {
		width: -webkit-fill-available;
	}
}
.activeOption {
	background: #0969da;
	border: none;

	> div h4, h5, small, label {
		color: $white !important;
	}
	> h5 {
		font-size: 20px;
	}
}
.logoImg {
	width: 84px;
    height: 84px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 16px;
    border: 1px solid #ddd;
}
.serviceImg {
	width: 300px;
    height: 200px;
    border-radius: 4px;

	@media (max-width: $mobile-big) {
		width: -webkit-fill-available;
		object-fit: cover;
    }
}
.contentBlock {
	display: flex;
	gap: 36px;
	justify-content: space-between;
	flex-wrap: wrap;
}
.packageOptionsBlock {
	background: #f6f8fa;
    margin-top: 48px;
    padding: 36px;
    border-radius: 12px;

	@media (max-width: $tablet) {
		padding: 0;
		background: none;
		margin-top: 16px;
	}
}
.optionContent {
	display: flex;
    gap: 36px;
    justify-content: center;
    margin: 36px 0px;
    flex-wrap: wrap;
}
.serviceTopBlock {
	display: flex;
    justify-content: space-between;
    margin: 36px 0px;
    flex-wrap: wrap;

	@media (max-width: $tablet) {
		justify-content: center;

		> div button {
			margin-top: 16px;
		}
    }
}
.titleService {
	display: flex;
    min-width: 450px;
    max-width: 450px;

	@media (max-width: $tablet) {
		min-width: -webkit-fill-available;
		max-width: -webkit-fill-available;
    }
}
.leftSide {
	min-width: 700px;
    max-width: 700px;

	@media (max-width: $tablet) {
		min-width: -webkit-fill-available;
		max-width: -webkit-fill-available;
    }
}
.rightSide {
	min-width: 250px;
    max-width: 250px;

	@media (max-width: $tablet) {
		min-width: -webkit-fill-available;
		max-width: -webkit-fill-available;
    }
}