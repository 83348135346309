
@import '../../../../scss/mixins';

.profileContainer {
    width: 100%;
    @include responsiveContainer;
}
.title {
    @include title;
}

.section {
    margin: 36px 0;
}
.summaryBlock {
    display: flex;
    justify-content: space-evenly;

    @media (max-width: $mobile) {
        flex-direction: column;
        align-items: center;
        margin: 8px;
    }
}
.sectionContent {
    display: flex;
    align-items: center;
    margin: 16px 0;
}
.extension {
    flex-direction: column;
    align-items: start;
}
.progressContainer {
    display: flex;
    align-items: center;
    width: -webkit-fill-available;
}
.breadcrumbBox {
    @include breadcrumbBox;
    background: none;
    padding: 0;
    margin-bottom: 8px;
}
.btn {
    @include btnWithIcon;
}
.expandibleBox {
    margin: 16px 0;
}
.expandibleText {
    margin: 16px 0;
}
.btnBox {
    display: flex;
    justify-content: flex-end;
}
.spaceText {
    margin: 8px 0;
}
.contentProgressContainer {
    margin: 36px 0;
}
.creditElement {
    margin: 0 8px;
}
.summaryBox {
    width: 200px;
    height: 100px;
    background-color: $primary-gray;
    border-radius: 12px;
    @include flexColumn;
    justify-content: center;
    align-items: center;
    margin: 0px 8px;
    padding: 16px;

    @media (max-width: $mobile) {
        margin: 8px;
    }
}
.elementBox {
    @include elementBox;
    @media (max-width: $mobile-big) {
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: -webkit-fill-available;
    }
}
.naming {
    @include naming;
    justify-content: flex-start;
    width: 200px;
}
.fileName {
    width: 150px;
    margin-left: 16px;
}
.notification {
    width: 100%;

    @media (max-width: $mobile) {
        max-width: -webkit-fill-available !important;
    }
}
.btn {
    display: inline !important;
    border-radius: 4px !important;
    padding: 0 !important;
    text-align: center !important;
    width: -webkit-fill-available !important;

    @media (max-width: $mobile) {
        max-width: -webkit-fill-available !important;
    }
}
.fileBtn {
    width: 180px !important;
}
.characteristics {
    @include characteristics;
    justify-content: space-between;
    width: 350px;
}
.invoiceDate {
    @media (max-width: $mobile-big) {
        display: none;
    }
}
.inputCharBox {
    @include inputCharBox;
}
.actions {
    @include actions;
    justify-content: flex-end;
    width: 252px;
}
.legalInfoContent {
    display: flex;

    @media (max-width: $mobile-big) {
        @include flexColumn;
        align-items: center;
    }
}
.personalData {
    @include flexColumn;
    border: 1px solid $secondary-gray;
    border-radius: 4px;
    padding: 8px 16px;

    @media (max-width: $mobile-big) {
        margin: 36px 0;
        width: -webkit-fill-available;
    }
}
.infoTitle {
    @include flexRow;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0;
    padding-bottom: 8px;
    border-bottom: 1px solid $tertiary-gray;

    & svg {
        margin-right: 8px;
    }

    a {
        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
}
.infoElements {
    @include infoElement;
}

.statusTag {
    border-radius: 4px !important;
}

.toggle {
    margin: 0 16px;
}

.link {
    cursor: pointer;
    color: $blue;
}