@import '../../scss/_mixins.scss';

.imageContainer {
    min-height: 156px;
}

.smallerImageContainer {
    min-height: 96px;
}

.projectCard {
    position: relative;
    background: $white;
    border: 1px solid $secondary-gray;
    border-radius: 8px;
    min-height: 316px;
    transition: box-shadow 0.3s;
    transition: opacity 0.3s;

    &:hover {
        box-shadow: 0 2px 10px rgba(242, 242, 242, 1);
        opacity: .95;
    }

    > .cardImage {
        &:hover {
            opacity: .55;
        }
    }
}
.projectLink {
    text-decoration: none;
    color: $primary-black !important;
}
.participatingTag {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    margin: 4px;
}
.inTag {
    background: $secondary-red !important;
    color: $white !important;
    letter-spacing: 1pt !important;
    text-transform: uppercase !important;
}
.tags {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    margin: 4px;
}
.cardImage {
    width: 100%;
    height: 156px;
    object-fit: cover;
    border-radius: 6px 6px 0 0;
}
.bottomBlock {
    @include flexColumn;
    justify-content: space-between;
}
.content {
    @include flexColumn;
    padding: 16px;
    min-height: 148px;
}
.titleAndButtons {
    @include flexRow;
}
.titleContainer {
    flex: 1;
    padding: 8px 0px;
    display: flex;
    flex-direction: column-reverse;
}
.title {
    margin-bottom: 8px;
}
.subtitle {
    font-size: small;
}
.favBtn {
    svg {
        cursor: pointer;
        max-width: 22px;
        background: transparent !important;
    }
}
.phaseStateContainer {
    margin: 16px 0;
    svg {
        max-width: 22px;
        max-height: 22px;
    }
}
.infoContainer {
    margin: 16px 0;
}
.description {
    margin-bottom: 16px;
}
.iconTags {
    min-width: 20%;
}
.btn {
    display: inline;
    text-align: center;
    padding: 0;
    width: -webkit-fill-available;
    border-radius: 4px;
}
.finishedTag {
    padding: 8px 12px;
    @include flexRow;
    justify-content: center;
    background: $main-red;
    color: $white;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin: 4px 0;
    border-radius: 4px;
}
.contributorsBubble {
    width: 36px;
    height: 36px;
    background: $white;
    border-radius: 50%;
    @include flexRow;
    @include centeredItems;
    color: $secondary-gray;
    font-weight: 600;
    margin: 4px;
}
.stepsContainer {
    margin: 12px 0;
}
.stepperBox {

    > ul > li {
        min-width: 6rem !important;
    }
}
.actionBox {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.white {
    color: $white;
}
.startBox {
    @include flexColumn;
    @include centeredItems;
    border: 1px dashed $tertiary-gray;
    cursor: pointer;
    color: $tertiary-gray;
    height: 352px;
    transition: 0.2s;

    > h4 {
        color: $tertiary-gray;
        transition: 0.2s;
    }

    &:hover {
        border: 1px solid $main-red;
        color: $main-red;
        box-shadow: none;
        background-color: $primary-gray;
        > h4 {
            color: $main-red;
        }
    }
}
.flatTag {
    width: 116px;
    border-radius: 4px !important;
    min-height: 1rem !important;
    height: 20px;
    margin: 0 !important;
}
.activeState {
    margin-left: 8px;
    color: $green;
    padding: 4px;
    padding-bottom: 1px;
}
@keyframes flickering {
    0%{
        transform: scale(0);
    }
    40%{
        transform: scale(1);
        opacity:1;
    }
    100%{
        opacity:0;
  }
}
.complementIcon {
    display: flex;
    align-items: center;
}
.link {
    color: $blue;
}
.complementInfo {
    margin-bottom: 12px;
    color: $tertiary-gray;
}
.altSign {
    position: absolute;
    right: 1rem;
    top: 1rem;
    background: $tertiary-gray;
    color: $white;
    border-radius: 16px;
    padding: 8px;
    display: flex;
    align-items: center;
    box-shadow: 0 6px 16px rgba(0,0,0,.2);
}
.altImgBtn {
    position: absolute;
    top: 40%;
    right: 33%;
    left: 33%;
    background: $white;
    color: $secondary-black;
    border-radius: 8px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0 6px 16px rgba(0,0,0,.2);
}
.extraContent {
    @include flexRow;
    justify-content: space-between;
    margin: 16px 0 0;
}
.infoElement {
    @include flexColumn;
    justify-content: flex-start;
}
.contributorsBlock {
    display: flex;
    flex-wrap: wrap;
    max-width: 126px;
    position: relative;

    :nth-child(even) {
        position: absolute;
        left: 20px;
        background-color: $tertiary-gray;
    }
}
.contributor {
    background-color: $secondary-gray;
    border-radius: 50%;
    display: flex;
    @include centeredItems;
    width: 36px;
    height: 36px;
    color: $white;
    border: 2px solid $white;
}
.contributorsList {
    display: flex;
    margin-top: 8px;
}
.phaseLines {
    width: 20px;
    height: 32px;
    margin-right: 4px;
    color: $white;
    border-radius: 4px;
    font-size: 0.7rem;
    display: flex;
    align-items: flex-end;
    padding: 2px;
    background-color: $secondary-gray;
}

.phasesContainer {
    display: flex;
    position: relative;
    position: sticky;
    margin: 8px 0px;

    :nth-child(2n) {
        position: absolute;
        left: 26px;
    }
    :nth-child(3n) {
        position: absolute;
        left: 52px;
    }
    :nth-child(4n) {
        position: absolute;
        left: 78px;
    }
    :nth-child(5n) {
        position: absolute;
        left: 104px;
    }
}
.phaseBubble {
    @include flexRow;
    align-items: flex-end;
    width: 48px;
    height: 36px;
    padding: 4px;
    border-radius: 4px;
    color: $white;
    font-weight: 600;
    font-size: 0.8rem;
    z-index: 1;
    background-color: rgb(233, 233, 233);
}
.gradientPhases {
    width: -webkit-fill-available;
    height: 6px;
    border-radius: 2px;
    position: absolute;
    top: -6px;
}
.circleLocked {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    padding: 8px;
    opacity: 0.5;

    &:hover {
        background: $tertiary-black;
        opacity: 1;

        > svg {
            fill: $white !important;
        }
    }
}

.progressCircle {
    position:absolute;
    left: 10px;
    background-color: transparent;
    color: $blue;
    margin-top: 3px;
}
.demoTag {
    position: absolute;
    top: 16px;
    right: 16px;
    border-radius: 16px;
    z-index: 3;
    box-shadow: 0 6px 16px rgba(0,0,0,0.12);
    padding: 8px 16px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.03em;
    line-height: 14px;
    background: #fe6601;
    color: $white;
    opacity: 0.7;
}
