.circle {
	padding: 8px;
	border-radius: 50%;
	background: transparent;
	transition: background 0.2s;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		box-shadow: 0 6px 16px rgba(0,0,0,.2);
		cursor: pointer;
		
		.icon {
			color: #df3d3b;
		}
	}
	&.active .icon {
		color: #df3d3b;
	}
}

.icon {
	color: #657786;
	transition: color 0.2s;
}
