@import '../../../../scss/mixins';

.scroll {
    margin: 16px 0;
    overflow: initial !important;
}
.loadingContainer {
    margin: 36px 0;

    > div {
        display: flex;
        justify-content: center;
    }
}
.gridList {
    @include flexRow;
    flex-wrap: wrap;
}
.gridItem {
    margin: 0 16px 16px 0;
    min-width: 326px;
    max-width: 326px;

    @media (max-width: $mobile) {
        min-width: -webkit-fill-available;
        margin: 0 0 16px 0;
    }
}
.cardSkeleton {
    height: 400px !important;
    border-radius: 4px;
}
.filterPhaseBox {
    width: 324px;
    margin-bottom: 36px;

    @media (max-width: $mobile) {
        width: -webkit-fill-available;
    }
}
.btnBottom {
    @include flexColumn;
    align-items: center;
    width: -webkit-fill-available;
}
.littleText {
    color: $tertiary-black;
	font-size: 0.75rem;
	letter-spacing: 0.32px;
	margin-bottom: 16px;
}
.projectInvolvedSkeleton {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $white;
    border: 1px dashed $secondary-gray;
    border-radius: 4px;
    min-height: 286px;
    cursor: pointer;
    color: $secondary-gray;
    transition: 0.2s;

    &:hover {
        background-color: $primary-gray;
        border: 1px solid $main-red;
        box-shadow: none;
        color: $main-red;

        h5 {
            color: $main-red;
        }
    }
}
.contestOption {
    padding: 16px;
    border: 1px dashed $secondary-gray;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    max-width: 1008px;

    > small {
        color: $tertiary-gray;
        &:hover {
            font-size: 1rem;
        }
    }
}
.blockContent {
    margin: 16px 0;
}
.horizontalScrolling {
    flex-wrap: nowrap;
    overflow-x: scroll;
    white-space: nowrap;
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    touch-action: manipulation;

    &:active {
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
    }
}