@import '../../../scss/mixins';

.btnBox {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;

    @media (max-width: $tablet) {
        @include flexColumn;
    }
}
.btn {
    @include btnPrimary;
}
.messageIcon {
    @include overviewMessageBox;
}
.smallAnchor {
    color: $secondary-red;
    margin-left: 8px;
    cursor: pointer;
}
.legend {
    font-size: 0.75rem;
    letter-spacing: 0.32px;
    display: inline-block;
    margin-bottom: 0.5rem;
    color: #525252;
    font-weight: 400;
    line-height: 1rem;
    vertical-align: baseline;
}
.labelSelection {
    font-weight: 600;
    font-style: italic;
}
.marginHeightShort {
    margin: 8px 0;
}
.marginHeightMedium {
    margin: 16px 0px;
}
.width {
    max-width: 500px;
    width: 100%;
}
.radioButtonGroup {
	width: 100%;
	
	& fieldset {
		width: 100%;
	}
}
.codeContainer {
    background-color: $primary-gray;
    padding: 16px;
    border-radius: 4px;

    > code {
        font-size: 0.75rem;
    }
}
.secondaryLink {
    @include link;
}
.contentTitle {
    @include contentTitle;
}