@import '../../../../scss/mixins';

.profileContainer {
    width: 100%;
    @include responsiveContainer;
}
.title {
    @include title;
}
.section {
    margin: 36px 0;
}
.sectionContent {
    display: flex;
    align-items: center;
    margin: 16px 0;
}
.breadcrumbBox {
    @include breadcrumbBox;
    background: none;
    padding: 0;
    margin-bottom: 8px;
}
.circle {
    width: 126px;
    height: 126px;
    background: $white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 16px;
    padding: 18px;
}
.contentSwitcherContainer {
    margin: 26px 16px;
    text-align: center;
}
.expertText {
    margin: 26px 16px;
    text-align: center;

    @media (max-width: $mobile) {
        margin: 16px 0;
    }
}
.topBox {
    @include flexColumn;
    @include centeredItems;
}
.expertTag {
    border-radius: 4px !important;
    text-transform: uppercase;
    font-weight: 600 !important;
}