@import '../../../scss/mixins';

.mainContent {
    display: flex;
    justify-content: center;
    transform: none;
}
.parentGrid {
    min-height: 100vh;
    overflow: hidden;
}
.title {
    margin-bottom: 26px;
    @include title;
}
.brandContainer {
    @include flexColumn;
    align-items: center;
    margin: 26px;
}
.logo {
    width: 126px;
    height: auto;
}
.cardContent {
    padding: 26px;
    min-width: 400px;
    max-width: 400px;
    background: $white;
    border-radius: 4px;
    border: 1px solid $secondary-gray;

    @media (max-width: $mobile) {
        max-width: -webkit-fill-available;
        min-width: -webkit-fill-available;
        width: -webkit-fill-available;
        border: none;
        padding: 0;
    }
}
.form {
    @include flexColumn;
}
.notification {
    margin-bottom: 16px;
    max-width: 100% !important;

    @media (max-width: $mobile) {
        max-width: -webkit-fill-available !important;
    }
}
.textInput {
    background-color: $primary-gray !important;
    border-bottom: none !important;
}
.rememberMe {
    margin-bottom: 16px;
}
.btnBox {
    display: flex;
    justify-content: center;
}
.submitButton {
    @include btnPrimary;
    max-width: -webkit-fill-available !important;
}
.additionalLinks {
    text-align: center;
    margin-top: 16px;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}
.error {
    margin-top: 16px;
}
.btn {
    @include btnPrimary;
}
.alertWrapper {
    padding: 10px;
    position: relative;
    display: flex;
    justify-content: center;
}
.alertBox {
    @include flexColumn;
    align-items: center;
    background-color: $primary-gray;
    width: max-content;
    padding: 3rem;
    max-width: 400px;
    border-radius: 8px;
}