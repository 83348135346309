@import '../../scss/_mixins.scss';

.addon {
	width: 326px;
	min-height: 400px;
    height: fit-content;
	border: 1px solid $secondary-gray;
	border-radius: 26px;
	margin: 0 16px 50px 0;

	@media (max-width: $mobile) {
		width: -webkit-fill-available;
		margin: 0 0 36px 0;
	}
}
.content {
	@include flexColumn;
}
.subtitle {
	text-transform: uppercase;
	color: $tertiary-gray;
	font-weight: 600;
}
.tag {
	border-radius: 4px !important;
	text-transform: capitalize;
}
.img {
	width: -webkit-fill-available;
	height: 200px;
	object-fit: cover;
	margin: 0 16px;
    border-radius: 4px;
	opacity: 0.9;
}
.price {
	position: absolute;
    top: 26px;
    right: 12px;
    background: $white;
    padding: 4px;
    border-radius: 12px;
    box-shadow: 0 6px 16px rgba(0,0,0,.2);
	text-align: center;
}
.bottomBlock {
	padding-top: 16px;
    border-top: 1px solid $secondary-gray;
	text-align: center;
}
.btn {
	@include btnWithIcon;
	min-width: -webkit-fill-available !important;
}
.altBtn {
	@include btnPrimary;
}
.logo {
	width: 56px;
    height: 56px;
	object-fit: cover;
	background: $white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -1.5rem;
    left: 1.5rem;
	border: 1px solid $secondary-gray;
}
.flag {
	width: 36px !important;
    height: 26px !important;
    object-fit: cover;
    background: $white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -1rem;
    right: 1.5rem;
    border: 1px solid $secondary-gray;
}
.platform {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0.5rem;
    right: 1.5rem;

	> img {
		width: 126px !important;
		height: 26px !important;
		object-fit: cover;
		background: $white;
		border-radius: 4px;
		border: 1px solid $secondary-gray;
		z-index: 2;
	}

	&:nth-child(2n) {
		top: 2.5rem !important;
	}
}
.littleText {
	color: $tertiary-black;
	font-size: 0.75rem;
	letter-spacing: 0.32px;
	margin-bottom: 16px;
}
.accordionContainer {
	display: flex;
	margin: 16px 0;
}
.priceBlock {
	@include flexColumn;
    align-items: flex-end;
    width: 96px;
    margin: 0px 16px;

	> small {
		text-align: end;
	}
}
.paddingMd {
	padding: 16px;
}
.flexColumn {
	@include flexColumn;
}
.flexEnd {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}
.flexCenter {
	display: flex;
	align-items: center;
}
.icon {
	margin-right: 8px;
}
.kpiText {
	font-weight: 600;
	margin: 4px 0;
	color: $tertiary-black;
}
.kpiContainer {
	display: flex;
	justify-content: space-between;
}
.textSelected {
	position: absolute;
	background-color: $blue;
    right: 25px;
    bottom: 9px;
    padding: 4px 10px;
	border-radius: 5px;
	opacity: 0.8;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	cursor: default;
	transition: 0.5s;
	z-index: 2;

	> h5 {
		font-style: italic;
		color: $white;
		text-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
		text-align: end;
		font-size: 13px;
	}

	&:hover {
		opacity: 1;
	}
}
.disabledAddon {
	opacity: 0.5;
	cursor: no-drop;
}

.placeholder {
	display: inline-block;
	background-color: rgb(219, 219, 219);
	background-color: rgb(241, 241, 241);
	height: 25px;
	width: 60%;
	border-radius: 4px;
	margin-bottom: 5px;
	vertical-align: middle;
}

.placeholder.provider {
	width: 30%;
	height: 16px;
}

.placeholder.description {
	width: 100%;
	height: 108px;
}

.disabledImg {
	filter: grayscale(100%);
	opacity: 0.7;
}
.swiperContainer {
	> div {
		object-fit: cover;
		margin: 0 16px;
		border-radius: 4px;
		opacity: 0.9;
	}
}
