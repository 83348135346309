.timeline {
	width: 100%;
	position: relative;
	min-width: 100%;
	background: rgb(253, 253, 253);
	padding-bottom: 96px;
/* 	height: 393px; */

	overflow-x: scroll;

	border: 1px solid rgb(233, 233, 231);
	border-radius: 5px;
}

.timeline::-webkit-scrollbar {
	display: none;
}

.verticality {
	width: 100%;
	height: 100%;
	overflow: hidden;
	cursor: default;
}

.evenWeek {
	position: absolute;
	width: 80px;
	height: 100%;
	background: rgb(247, 247, 247);
}

.todayLine {
	position: absolute;
	height: 100%;
	border-right: 1px solid rgb(211, 79, 67);
}

.selectedDate {
	color: #333 !important;
	font-weight: 600;
}

.bubble {
	position: absolute;
	top: 25px;
	width: 10px;
	height: 10px;
	background: rgb(211, 79, 67);
	border-radius: 100%;
}

.horizontality {
	position: absolute;
	width: 100%;
	z-index: 0;
	top: 40px;
}

.row {
	display: flex;
	height: 33px;
	width: 100%;
}

.item {
	position: absolute;
	margin-top: 1px;
	margin-bottom: 1px;
	width: 197px;
	height: 30px;
	border-radius: 4px;
	display: flex;
	background: white;
	box-shadow: 0px 0px 0px 1px rgba(15, 15, 15, 0.1) inset, 0px 1px 2px rgba(15, 15, 15, 0.1);
	cursor: pointer;
}

.itemContents {
	width: 100%;
	/* mask-image: linear-gradient(90deg, rgb(0, 0, 0) 210px, rgba(0, 0, 0, 0.4) 210px); */
	pointer-events: none;
}

.itemContent {
	display: flex;
	height: 31px;
/* 	width: 800px; */
	padding-left: 6px;
	overflow: hidden;
	position: absolute;
}

.itemContentTextWrapper {
	display: flex;
	margin-right: 10px;
	font-size: 14px;
	font-weight: 600;
	align-items: center;
}

.itemContentEmoji {
	user-select: none;
	transition: background 20ms ease-in 0s;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 20px;
	width: 20px;
	border-radius: 0.25em;
	flex-shrink: 0;
	margin-left: 2px;
	margin-right: 6px;
}

.itemEmojiWrapper {
	display: flex; align-items: center; justify-content: center; height: 20px; width: 20px;
}

.itemEmojiOuter {
	height: 18px; width: 18px; font-size: 18px; line-height: 1; margin-left: 0px; color: black;
}

.itemContentText {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 400px;
	height: 15px;
	margin-top: 1px;
}

.itemContentTagWrap {
	display: flex;
	align-items: center;
	font-size: 14px;
	margin-right: 10px;
	flex-shrink: 0;
}

.itemContentTagOuter {
	display: flex;
	min-width: 0px;
	margin-right: -6px;
}

.itemContentTag {
	display: flex;
	align-items: center;
	flex-shrink: 1;
	min-width: 0px;
	height: 18px;
	border-radius: 3px;
	padding-left: 8px;
	padding-right: 8px;
	font-size: 13px;
	line-height: 120%;
	color: rgb(76, 35, 55);
	background: rgb(245, 224, 233);
	margin: 0px 6px 0px 0px;
}

.dateSection {
	position: absolute;
	left: 0px;
	top: 0px;
	background: white;
	border-top: 1px solid transparent;
	border-bottom: 1px solid rgb(233, 233, 231);

	padding-left: 50px;
}

.dateWrapper {
	padding-top: 2px;
	height: 33px;
	position: relative;
	overflow: hidden;
}

.date {
	position: absolute;
	width: 40px;
	height: 28px;
	display: flex;
	align-items: flex-start;
	line-height: 28px;
}

.dateContent {
	font-size: 12px;
	margin: 0px auto;
	color: rgba(55, 53, 47, 0.5);

	font-size: 11px;
	color: rgba(55, 53, 47, 0.3);
}

.locked {
	background-color: rgba(203, 203, 203, 0.466);
	background-color: rgba(246, 246, 246, 0.776);
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 10;
	display: flex;
	align-items: center;
	justify-content: center;
}

.lockedButton {
	font-size: 16px;
	background-color: #FFA201;
	color: #fff;
	cursor: pointer;
	padding: 15px;
	border-radius: 6px;
	padding-left: 20px;
	padding-right: 20px;
	box-shadow: 1px 1px 10px 1px rgb(204, 204, 204);
	transition: background-color 0.3s;
	user-select: none;
}

.lockedButton:hover {
	background-color: #ffa60d;
}

.bottomArea {
	display: flex;
	justify-content: flex-end;
	margin-top: 10px;
}

.eta {
	font-size: 13px;
	color: rgb(116, 116, 116);
	
}