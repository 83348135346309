$main-red:          #dd0031;
$secondary-red:     #df3d3b;

$primary-black:     #313131;
$secondary-black:   #3e3e3e;
$tertiary-black:    #454A50;

$primary-gray:      #f5f5f5;
$secondary-gray:    #e1e4e8;
$tertiary-gray:     #c5c5c5;
$quaternary-gray: 	#848D95;
$quinternary-gray:	#DDDDDD;

$white:             #fff;
$light-blue:	    #2C87C3;
$blue:              #0062ff;
$purple:			#7145d6;
$green:             #24a148;
$yellow:            #dc9a00;
$gold:              #ffc637;

$desktop-big:       1440px;
$desktop:           1160px;
$tablet:            1024px;
$mobile-big:        768px;
$mobile:            400px;

$font-family:       'Source Sans Pro', sans-serif;
