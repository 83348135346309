@import '../../../scss/_mixins.scss';

.contestList {
    width: 100%;
    @include responsiveContainer;
}
.title {
    @include title;
}
.filtersContainer {
    margin: 16px 0;
    span {
        cursor: pointer;
    }
}
.filterPhaseBox {
    width: 324px;
    margin-bottom: 36px;

    @media (max-width: $mobile) {
        width: -webkit-fill-available;
    }
}
.contestType {
    text-transform: uppercase;
}
.contests {
    @include flexRow;
    flex-wrap: wrap;
    margin: 36px 0;
    @include responsiveContainer;
    @media (max-width: $tablet) {
        padding-left: 0;
        padding-right: 0;
    }
}
.contestCard {
    border: 1px solid $secondary-gray;
    border-radius: 4px;
    padding: 16px;
    width: -webkit-fill-available;
    max-width: 900px;
    margin-bottom: 16px;

    &:hover {
        box-shadow: 0 6px 16px rgba(0,0,0,.2);
        transform: translateY(-2px);
		cursor: pointer;
    }
}
.topCard {
    @include flexRow;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: $mobile-big) {
        @include flexColumn;
    }
}
.tag {
    border-radius: 0 !important;

    @media (max-width: $mobile-big) {
        margin: 16px 0;
    }
}
.bodyCard {
    @include flexRow;
    justify-content: space-between;
    margin-top: 16px;

    @media (max-width: $mobile-big) {
        @include flexColumn;
    }

    .leftSideContent {
		max-width: 450px;
		
        @media (max-width: $mobile-big) {
			max-width: 100%;
        }
    }
    .rightSideContent {
        flex: 1;
        margin-left: 36px;

        @media (max-width: $mobile-big) {
            margin: 0;
        }

        .contestInfo {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
.lastBlock {
    margin: 36px 0;
}
.listModal {
    padding: 0 16px;
    margin: 16px;
    list-style-type: disc;

    li  {
        margin: 12px 0;
    }
}
.projectCard {
	height: 100%;
    width: 450px;
	
    @media (max-width: $mobile-big) {
        margin-bottom: 16px;
        width: -webkit-fill-available;
        height: auto;
    }
}
.projectCardImg {
	width: 100%;
	height: 100%;
    max-height: 348px;
    border-radius: 4px;
    object-fit: cover;
}
.notification {
    width: 100% !important;
	min-width: 100% !important;
	max-width: 100% !important;
    margin: 0 0 1rem 0 !important;
}
.description {
    margin: 12px 0;
	word-break: break-word;
}
.features {
    @include flexRow;
    justify-content: space-between;
    margin: 8px 16px;
}
.btn {
    display: inline !important;
    border-radius: 4px !important;
    padding: 0 !important;
    text-align: center !important;
    width: -webkit-fill-available !important;

    @media (max-width: $mobile) {
        max-width: -webkit-fill-available !important;
    }
}
.joinAction {
    display: flex;
    justify-content: center;
}
.returnActionModal {
    @include flexRow;
    align-items: center;
    margin-bottom: 16px;

    span {
        color: $secondary-red;
    }
}
.linkModal {
    @include link;
}
.modalContent {
    padding: 0 36px;
}
.contestContent {
    margin: 16px 0;
    p {
        padding: 4px 16px;
    }
}
.contestImg {
    width: 100%;
    height: 400px;
    object-fit: contain;
}
.sideModal {
    background: $white;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    width: 500px;
    z-index: 10000;

    @media (max-width: $mobile-big) {
        max-width: -webkit-fit-content;
    }
}
.sideHeader {
    @include flexRow;
    justify-content: space-between;
    padding: 16px 26px;
    border-bottom: 1px solid $primary-gray;
}
.sideContent {
    padding: 16px 26px;
}
.elementBox {
    @include elementBox;
}
.naming {
    @include naming;
}
.actions {
    @include actions;
    width: 100px;
}
.breadcrumbBox {
    @include breadcrumbBox;
}
.block {
    @include responsiveContainer;
    margin: 16px 0;
}

.dateBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
}
.finishedTag {
    padding: 12px;
    @include flexRow;
    justify-content: center;
    background: $main-red;
    color: $white;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin: 2px 0;
    border-radius: 4px;
}
.contributorsBubble {
    width: 36px;
    height: 36px;
    background: $white;
    border-radius: 50%;
    @include flexRow;
    @include centeredItems;
    color: $secondary-gray;
    font-weight: 600;
    margin: 4px;
}
.contestTag {
    border-radius: 4px !important;
    margin: 10px 0 !important;
}