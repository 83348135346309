@import '../../../scss/mixins';

.projectContainer {
    @include responsiveContainer;
}
.title {
    @include title;
}
.subtitle {
    margin-top: 8px;
    font-weight: 400;
}
.contentBlock {
    margin: 36px 0;
}
.contentTitle {
    @include contentTitle;
}
.contestsStates {
    margin: 64px 0;

    @media (max-width: $mobile-big) {
        display: none;
    }
}
.contestsStatesMobile {
    display: none;
    margin: 16px 0;

    @media (max-width: $mobile-big) {
        display: inherit;
    }
}
.accordion {
    margin: 36px 0;
}
.elementBox {
    @include elementBox;
}
.naming {
    @include naming;
}
.actions {
    @include actions;
    width: 100px;
}
.btn {
    @include btnPrimary;
}
.btnContainerSkeleton {
    @include flexRow;
    justify-content: flex-end;
    margin: 36px 0;
}
.materialList {
    display: flex;
    flex-wrap: wrap;
}
.skeletonSteps {
    display: flex;
    justify-content: center;

    @media (max-width: $mobile-big) {
        display: none;
    }
}
.skeletonBubble {
    margin: 0 8px;
    border-radius: 50%;
    width: 82px !important;
    height: 82px !important;	
	overflow: hidden;
}
.skeletonProgressIndicator {
    margin: 36px 0;
    display: flex;
    justify-content: center;
    @media (max-width: $mobile) {
        display: none;
    }
}
.skeletonContestContent {
    display: flex;
    justify-content: space-around;

    @media (max-width: $mobile-big) {
        flex-direction: column;
    }
}
.skeletonContestCard {
    width: 400px !important;
    height: 200px !important;

    @media (max-width: $mobile-big) {
        width: -webkit-fill-available !important;
    }
}
.skeletonContestBox {
    width: 200px !important;
    height: 200px !important;
}
.rawMaterialContainer {
    @include flexColumn;
}
.materialCard {
    margin: 8px;
    border-radius: 8px;
    border: 1px solid $secondary-gray;

    &:hover {
        transform: translateY(-2px);
		cursor: pointer;
    }

    .cardImg {
        width: 316px;
        height: 136px;
        opacity: .5;
        object-fit: cover;
        background-color: rgba(0, 0, 0, .5);
        border-radius: 4px 4px 0 0;
    }

    .cardName {
        height: 36px;
        background: $white;
        border-radius: 0 0 6px 6px;
        padding: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .name {
            color: $secondary-black;
        }
    }
}
.switcherContainer {
    display: flex;
    justify-content: flex-end;
    margin: 16px;
}
.marginHeightShort {
    margin: 8px 0;
}
.marginHeightMedium {
    margin: 16px 4px;
}
.messageIcon {
    @include overviewMessageBox;
}
.configContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0;
}
.projectImage {
    width: 100%;
    height: 226px;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
    filter: grayscale(100%);
}
.hideBtn {
    color: $white;
    border-radius: 4px;
    position: absolute;
    right: 16px;
    bottom: 16px;
    width: 100px;
    @include flexRow;
    @include centeredItems;
    padding: 8px;
    opacity: 0.5;
    cursor: pointer;

    &:hover {
        background: $tertiary-black;
        color: $white;
        opacity: 1;
    }
}