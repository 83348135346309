@import '../../../scss/mixins';

.container {
    @include responsiveContainer;
}
.title {
    @include title;
}
.subtitle {
    margin: 8px 0;
}
.accordion {
	margin: 26px 0 116px 0;
}
.list {
    list-style: circle;
}
.listItem {
	margin: 16px 0;
}
.dontImg {
	width: 500px;
	height: auto;

	@media (max-width: $mobile-big) {
		width: -webkit-fill-available;
	}
}
.elementsContainer {
	display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 36px 0px;
    gap: 16px;
}
.partnersLogo {
	width: 100%;
    max-width: 200px;
    height: 100%;
    max-height: 140px;
    object-fit: contain;
}
.actionOption {
	padding: 16px;
    border: 1px dashed $secondary-gray;
    border-radius: 12px;
    display: flex;
    justify-content: center;

    > small {
        color: $tertiary-gray;
        &:hover {
            font-size: 1rem;
        }
    }
}
.bottomBlock {
	position: static;
    bottom: 2rem;
    left: 6rem;
    right: 6rem;

	@media (max-width: $mobile-big) {
		left: 2rem;
		right: 2rem;
	}
}
.caseStudiesList {
	margin: 16px 0;
	display: flex;
	flex-wrap: wrap;
    gap: 8px;
}
.btn {
    @include btnPrimary;
    width: 200px !important;
}
.btnContainer {
    @include flexRow;
    justify-content: space-between;
    flex-wrap: wrap;

    position: fixed;
    bottom: -64px;
    left: 50%;
    right: 50%;
    background-color: $white;
    width: 100%;
    border-top: 1px solid $primary-gray;
    padding: 36px 0;
    @include responsiveContainer;
    transform: translate(-50%,-50%);
    z-index: 2;

    @media (max-width: $mobile-big) {
        padding: 2rem;
        justify-content: center;
    }
}
.unique {
    justify-content: flex-end;

    @media (max-width: $mobile-big) {
        justify-content: center;
    }
}
.card {
    width: 300px;
    height: 200px;
    @include flexColumn;
    align-items: center;
    justify-content: center;
    background: $primary-gray;
    border-radius: 12px;
    padding: 16px;

    > h2 {
        text-transform: uppercase;
        margin-bottom: 16px;
    }
}