@import './variables.scss';

@mixin flexRow {
    display: flex;
    flex-direction: row;
}

@mixin flexColumn {
    display: flex;
    flex-direction: column;
}

@mixin centeredItems {
    justify-content: center;
    align-items: center;
}

@mixin responsiveContainer {
    max-width: $desktop;
    margin-left: auto;
    margin-right: auto;
    padding-left: 64px;
    padding-right: 64px;
    margin-top: 10px;

    @media (max-width: $desktop-big) {
        padding-left: 40px;
        padding-right: 40px;
    }
    @media (max-width: $desktop) {
        max-width: 100%;
    }
    @media (max-width: $tablet) {
        padding-left: 32px;
        padding-right: 32px;
    }
    @media (max-width: $mobile-big) {
        padding-left: 0;
        padding-right: 0;
    }
    @media (max-width: $mobile) {
        padding-left: 0;
        padding-right: 0;
    }
}

@mixin btnPrimary {
    display: inline !important;
    border-radius: 4px !important;
    padding: 0 !important;
    text-align: center !important;
    width: -webkit-fill-available !important;
    font-size: 1rem !important;

    @media (max-width: $tablet) {
        max-width: -webkit-fill-available !important;
    }
}

@mixin btnWithIcon {
    border-radius: 4px !important;
    font-size: 1rem !important;

    @media (max-width: $tablet) {
        max-width: -webkit-fill-available !important;
    }
}

@mixin title {
    font-weight: 600;
}

@mixin breadcrumbBox {
    background: $primary-gray;
    padding: 16px;
    border-radius: 4px;
    width: -webkit-fill-available;

    @media (max-width: $mobile) {
        padding: 16px;
        ol {
            @include flexRow;
        }
    }
    a {
        text-decoration: none;
        color: $main-red;
        font-weight: 600;
    }
}

@mixin avatar {
    width: 126px;
    height: 126px;
    background: $secondary-gray;
    border-radius: 50%;
    @include flexRow;
    @include centeredItems;
    color: $white;
    font-weight: 600;
    margin: 16px;
    font-size: 48px;
}

@mixin avatarImg {
    width: 126px;
    height: 126px;
    border-radius: 50%;
    margin: 16px;
}

@mixin infoElement {
    @include flexRow;
    justify-content: space-between;
	align-items: center;
    width: 400px;
    margin: 8px 0;

    @media (max-width: $mobile-big) {
        width: 100%;
    }
}

@mixin link {
    color: $blue;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

@mixin projectStatusQuickAccess {
    padding: 16px 0 8px 0;
    border-top: 1px solid $tertiary-gray;
    @include flexRow;
    justify-content: center;
        a {
            text-transform: uppercase;
            font-weight: 600;
            font-size: .65rem;
            text-decoration: none;
            letter-spacing: 1px;
        }
}

@mixin listContent {
    padding: 0 16px;
    margin: 16px;
    list-style-type: decimal;

    li  {
        margin: 8px 0;
        font-size: 1rem;
    }
}

@mixin contentTitle {
    margin-bottom: 16px;

    h3 {
        margin: 32px 0;
    }
    h4 {
        @include title;
    }
}

@mixin overviewMessageBox {
    margin: 16px 0;
    @include flexRow;
    align-items: center;

    svg {
        margin-right: 8px;
    }
}

@mixin characteristics {
    @include flexRow;
    align-items: center;
    justify-content: space-evenly;
    width: 450px;

    @media (max-width: $mobile) {
        width: -webkit-fill-available;
        margin: 8px;
    }
}

@mixin inputCharBox {
    @include flexRow;
    justify-content: space-between;
    align-items: center;
    width: 226px;
}

@mixin actions {
    @include flexRow;
    align-items: center;
    justify-content: space-between;

    a,
    span.link {
        cursor: pointer;
        color: $blue;
    }
}

@mixin elementBox {
    border: 1px solid $secondary-gray;
    border-radius: 4px;
    @include flexRow;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    margin: 16px 0;

    &:hover {
        background: $primary-gray;
        border: 1px solid $primary-gray;
    }
}

@mixin naming {
    @include flexRow;
    align-items: center;
    justify-content: space-between;

    svg {
        margin-right: 16px;
    }
}

@mixin updateBox {
    border-bottom: 1px solid $primary-gray;
    border-radius: 4px;
    @include flexRow;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    margin: 8px 0;

    /*
    &:hover {
       box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px, rgba(0, 0, 0, 0.3) 0px 4px 28px;
    }
     */
}

@mixin proposalImg {
    min-width: 348px;
    max-width: 425px;
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 4px 4px 0 0;
    background: $primary-gray;
    filter: brightness(.95);

    @media (max-width: $mobile) {
        min-width: 100%;
        max-width: 100%;
        height: auto;
    }
}